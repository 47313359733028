import { SearchOutlined } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined'
import { CircularProgress, IconButton, InputAdornment, InputBase, Typography } from '@mui/material'
import { Box } from '@mui/system'
import apis from 'apis'
import useAPIMethod from 'hooks/useApiMethod'
import { debounce } from 'lodash'
import { useMemo, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { useNavigate } from 'react-router-dom'
import useUserStore from 'store/userStore'
import { themeColor } from 'theme'
import { TContact } from 'types/contact'
import { TEvent } from 'types/global.types'

type TContactSearchDropdown = {
  loading?: boolean
}

const ContactSearchDropdown = ({ loading }: TContactSearchDropdown) => {
  const [inputValue, setInputValue] = useState('')

  const [contactList, setContactList] = useState<TContact[]>([])
  const navigate = useNavigate()
  const { callApi, isLoading } = useAPIMethod()
  const { userDetails } = useUserStore()

  const getContactList = useMemo(
    () =>
      debounce(async () => {
        try {
          const res = await callApi(() => apis.contact.getContactList(userDetails?.userId))
          setContactList(res.data)
        } catch {
          // err
        }
      }, 400),
    [],
  )

  const onSearch = (val: string) => {
    setInputValue(val)
    getContactList()
  }

  const styles = {
    dropdownContainer: {
      width: '100%',
      height: inputValue ? '340px' : '0px',
      borderRadius: '4px',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      position: 'absolute',
      bgcolor: themeColor.light.background,
      transition: '.3s ease-in-out all',
      zIndex: 99,
      overflow: 'auto',
      top: '50px',
      left: '-24px',
    },
    contactRow: {
      overflow: 'auto',
      p: '10px 20px',
      m: '0 auto',
      borderRadius: '4px',
      '&:hover': { bgcolor: themeColor.light.backgroundShade, cursor: 'pointer' },
    },
  }

  const searchData = inputValue
    ? contactList?.filter((el) => {
      return Object.values(el).some((val) => String(val).toLowerCase().includes(inputValue.toLowerCase()))
    }) || []
    : []

  const gotoContactDetails = (id: number) => {
    navigate(`/contacts/${id}`)
    setInputValue('')
  }

  const SearchText = (value: string) => {
    return (
      <Highlighter
        searchWords={[inputValue]}
        autoEscape={true}
        textToHighlight={value}
        highlightStyle={{
          color: themeColor.light.primaryMain,
          backgroundColor: 'transparent',
          fontWeight: 'bold',
        }}
      />
    )
  }

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
        <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
          {isLoading ? <CircularProgress size='20px' /> : <SearchOutlined />}
        </IconButton>
        <InputBase
          value={inputValue}
          sx={{ width: '100%', ml: 1, mr: 3 }}
          placeholder='Search Contacts....'
          inputProps={{ 'aria-label': 'search contact' }}
          onChange={(e: TEvent) => onSearch(e.target.value)}
          endAdornment={
            <InputAdornment position='end' sx={{ cursor: 'pointer' }}>
              {loading && <CircularProgress size='20px' />}
              {inputValue && <ClearIcon onClick={() => setInputValue('')} />}
            </InputAdornment>
          }
        />
      </Box>

      <Box sx={styles.dropdownContainer}>
        <Box p={2} sx={{ height: '100%' }}>
          <Typography ml={3} mb={1} variant='body2' color={themeColor.light.secondaryTextColor}>
            YOUR CONTACTS
          </Typography>
          {searchData.map((c, i) => (
            <Box onClick={() => gotoContactDetails(c.id)} key={i} sx={styles.contactRow}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PermIdentityOutlinedIcon
                  sx={{ color: themeColor.light.secondaryTextColor, fontSize: '24px' }}
                />
                <Typography
                  ml={1}
                  color={themeColor.light.secondaryTextColor}
                  sx={{ fontWeight: 700, fontSize: '16px' }}
                >
                  {SearchText(c.firstName)} {SearchText(c.lastName)}
                </Typography>
              </Box>
              <Box
                sx={{ display: 'flex', ml: 4, alignItems: 'center', gap: '10px', opacity: '0.6' }}
              >
                <EmailOutlinedIcon
                  sx={{ color: themeColor.light.secondaryTextColor, fontSize: '16px' }}
                />
                <Typography variant='body2' color={themeColor.light.secondaryTextColor}>
                  {SearchText(c.emailAddress)}
                </Typography>
                <PhoneAndroidOutlinedIcon
                  sx={{ color: themeColor.light.secondaryTextColor, fontSize: '16px' }}
                />
                <Typography variant='body2' color={themeColor.light.secondaryTextColor}>
                  {SearchText(c.phoneNumber)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default ContactSearchDropdown
