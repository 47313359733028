import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material';
import _ from 'lodash';
import apis from 'apis'
import { TNote } from 'types/contact'
import { useApiMethod } from 'hooks'
import { AddNote, NoteHistory } from './NoteUI';

const Note = () => {
  const { id: contactId = 0 } = useParams()
  const [notes, setNotes] = useState<TNote[]>([]);
  const { callApi: getTaskData, isLoading: cLoading, } = useApiMethod()
  const isLoading = cLoading

  const getRecords = async () => {
    try {
      if (contactId) {
        const resp = await getTaskData(() => apis.contact.getNotes(contactId))
        const data = resp.data ? _.orderBy(resp.data, ['id'], ['desc']) : [];
        setNotes(data);
      }
    } catch {
      // err
    }
  }

  useEffect(() => {
    getRecords();
  }, [contactId])

  const updateData = (item: TNote, type = 'edit') => {
    let items = [];
    if (type === 'delete') {
      items = notes.filter((row: TNote) => (row.id !== item.id)) || [];
    } else {
      items = notes.map((row: TNote) => (row.id === item.id ? item : row));
    }
    setNotes(items);
  }

  return (
    <>
      <Box>
        <AddNote callback={getRecords} />
      </Box>
      <Box sx={{ paddingTop: '1rem' }}>
        <NoteHistory isLoading={isLoading} notes={notes} callback={updateData} />
      </Box>
    </>
  )
}

export default Note
