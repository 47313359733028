import { styled } from '@mui/material/styles';
import { useFormContext } from 'react-hook-form';
import { UserSearchIcon } from 'assets/svg'
import { AutocompleteField, DataGrid, TDataGridHeadCell, CheckboxField } from 'components';

const Img = styled('img')(() => ({
    cursor: 'pointer',
    height: '24px', width: '24px'
}));

const ResourceTable = ({ data }: any) => {
    const { watch } = useFormContext()
    const fields = watch('assignedResources')
    console.log('fields ---', fields)

    const cells: TDataGridHeadCell[] = [
        {
            label: '',
            name: 'actions',
            props: { align: 'center', sx: { width: '80px' } },
            render: () => <Img src={UserSearchIcon} alt='' />
        },
        {
            label: 'Title',
            name: 'title',
        },
        {
            label: 'Resource',
            name: 'resource',
            props: { sx: { width: '230px' } },
            render: ({ row, index }) => {
                if (row?.contactResources?.length) {
                    const options = row.contactResources.map((item: any) => ({ label: `${item.firstName} ${item.lastName}`, value: item.id }))
                    return <AutocompleteField
                        textFieldProps={{ placeholder: 'Please Select..', size: 'small' }}
                        name={`assignedResources.${index}.resourceId`}
                        options={options}
                    />
                }
                return '-'
            }
        },
        {
            label: 'Phone',
            name: 'phone',
            props: { sx: { width: '230px' } },
            render: ({ row, index }) => {
                return <div>
                    {row.contactResources?.find((item: any) => (item.id === fields[index]?.resourceId?.value))?.phoneNumber?.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3').toString() || '-'}
                </div>
            }
        },
        {
            label: 'Email',
            name: 'contactResources[0]?.firstName',
            props: { sx: { width: '230px' } },
            render: ({ row, index }) => {
                return (<div>
                    {row.contactResources?.find((item: any) => (item.id === fields[index]?.resourceId?.value))?.emailAddress || '-'}
                </div>)
            }
        },
        {
            label: 'Status Updates',
            name: 'isComplete',
            render: ({ index }) => (
                <div>
                    <CheckboxField
                        name={`assignedResources.${index}.receiveStatusUpdates`}
                        sx={{ padding: 'unset' }}
                    />
                </div>
            ),
        },
    ];

    return (
        <DataGrid
            cells={cells}
            data={data?.contactLeadResources || []}
            hidePagination
        />
    )
}

export default ResourceTable;