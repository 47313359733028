import { Link, useNavigate } from 'react-router-dom'
import { Container, Grid, Button, Card, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginValidationSchema, loginInitialValues } from 'utils'
import { TextInputField, PasswordInputField } from 'components'
import apis from 'apis'
import cn from 'classnames'
import style from 'pages/styles/signin.module.scss'
import { Logo } from 'assets'
import { useNotification } from 'hooks'
import { TLoginResponse } from 'types/auth'
import useUserStore from 'store/userStore'

const SignIn = () => {
  const navigate = useNavigate()
  const notification = useNotification()
  const { setUserDetails } = useUserStore()

  const formProps = useForm({
    mode: 'all',
    resolver: yupResolver(loginValidationSchema),
    defaultValues: loginInitialValues,
  })

  const {
    formState: { isValid, isDirty },
    handleSubmit,
  } = formProps

  const submitHandler = async (values: { email: string; password: string }) => {
    try {
      const res = await apis.auth.login<TLoginResponse>({
        userName: values.email,
        password: values.password,
      })
      setUserDetails(res.data.data)
      localStorage.setItem('token', res.data.data.token)
      navigate('/')
    } catch (err: any) {
      notification.showError(err.response.data.message || 'Something went wrong!')
    }
  }

  return (
    <div className={cn(style.wrapper)}>
      <Container className={cn(style.containerCard)}>
        <Card className={cn(style.shadowCard)}>
          <Grid container spacing={0} className={cn(style.innerContainer)}>
            <Grid item sm={12} lg={4}>
              <div className={cn(style.leftBox)}>
                <img src={Logo} alt='logo' className={cn(style.logo)} />
                <Typography variant='h4' marginBottom='10px' color='#fff' align='center'>
                  Welcome Back!
                </Typography>
                <Typography
                  color='#fff'
                  marginBottom='20px'
                  align='center'
                  fontWeight='400'
                  sx={{ opacity: '0.80', fontFamily: 'Open Sans' }}
                >
                  Don’t have an account? Click the link below to sign up.
                </Typography>
                <Button
                  className={cn(style.whiteBorderBtn)}
                  variant='outlined'
                  onClick={() => navigate('/sign-up')}
                >
                  SIGN UP
                </Button>
              </div>
            </Grid>
            <Grid item sm={12} lg={8}>
              <div className={cn(style.formboxWrapper)}>
                <div className={cn(style.formBox)}>
                  <FormProvider {...formProps}>
                    <div className={cn(style.singinBox)}>
                      <div className={cn(style.signinWrapper)}>
                        <div className={cn(style.form)}>
                          <Grid container spacing={2}>
                            <Grid item sm={12}>
                              <Typography
                                variant='h5'
                                align='left'
                                className={cn(style.Title1)}
                                color='#000;'
                              >
                                Login
                              </Typography>
                            </Grid>
                            <Grid item sm={12}>
                              <TextInputField label='Email' name='email' placeholder='Email' />
                            </Grid>
                            <Grid item sm={12}>
                              <PasswordInputField
                                label='Password'
                                name='password'
                                placeholder='Password'
                                onKeyPress={(event: any) => {
                                  if (event.which === 13) {
                                    document.getElementById('submitForm')?.focus();
                                  }
                                }}
                              />
                            </Grid>
                            <Grid item sm={12}>
                              <Link
                                to='/forgot-password'
                                className={cn(style.forgotPassword, 'text-primary')}
                              >
                                Forgot Password{' '}
                              </Link>
                            </Grid>
                            <Grid item sm={12}>
                              <Button
                                id='submitForm'
                                variant='contained'
                                size='large'
                                fullWidth
                                onClick={handleSubmit(submitHandler)}
                                disabled={!isValid || !isDirty}
                              >
                                SIGN IN
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </FormProvider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </div>
  )
}

export default SignIn
