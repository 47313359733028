import { EyeOffIcon, MailForwardIcon } from 'assets/svg';

export const priorityOptions = [
    { value: 'urgent', label: 'Urgent' },
    { value: 'high', label: 'High' },
    { value: 'normal', label: 'Normal' },
]

export const purposeOptions = [
    { value: 'purchase', label: 'Purchase' },
    { value: 'rateandTermRefinance', label: 'Rate and Term Refinance' },
    { value: 'cashOutRefinance', label: 'Cash Out Refinance' },
    { value: 'construction', label: 'Construction' },
    { value: 'other', label: 'Other' },
]

export const primaryFocusOptions = [
    { value: 'monthlyPayment', label: 'Monthly Payment' },
    { value: 'homePrice', label: 'Home Price' },
    { value: 'downPayment', label: 'Down Payment' },
]

export const noteTypeOptions = [
    { value: 'privateNote', label: 'Private Note', icon: EyeOffIcon },
    { value: 'publicNote', label: 'Public Note', icon: MailForwardIcon },
]