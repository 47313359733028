import { Grid, Stepper, Step, StepLabel } from '@mui/material'
import { TLoanInformation, TLoanMilestone } from 'types/contact'

const Milestones = ({ data, milestonesMap }: { data: TLoanInformation, milestonesMap: any }) => {

    return (
        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            <Grid item xs={12}>
                <Stepper alternativeLabel>
                    {data.loanMilestones.map((item: TLoanMilestone) => {
                        const mapItem = milestonesMap.find((mile: any) => (mile.id === item.id));
                        return (
                            <Step completed={mapItem.isComplete} key={item.id}>
                                <StepLabel>{item.name}</StepLabel>
                            </Step>
                        )
                    }
                    )}
                </Stepper>
            </Grid>
        </Grid>
    )
}

export default Milestones
