import { Grid, Button } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInputField, AutocompleteField } from 'components'
import { TLoanInfoInitialValues } from 'types/contact'
import { contactLoanInfoValidator, LoanInfoInitialValues, purposeOptions, primaryFocusOptions } from 'utils'

const EditLoanInfo = () => {
    const formProps = useForm({
        mode: 'all',
        resolver: yupResolver(contactLoanInfoValidator),
        defaultValues: LoanInfoInitialValues,
    })

    const {
        handleSubmit,
        formState: { isValid, isDirty },
    } = formProps

    const onSaveChanges = async (values: TLoanInfoInitialValues) => {
        console.log('values ----> ', values)
    }

    return (
        <>
            <FormProvider {...formProps}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={6}>
                        <TextInputField
                            type='number'
                            label='Loan Number'
                            name='loanNumber'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextInputField
                            type='number'
                            label='Escrow Number'
                            name='escrowNumber'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12} container spacing={2} columns={10}>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='Subject Address'
                                name='subjectAddress'
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='City'
                                name='city'
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='Country'
                                name='country'
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='State'
                                name='state'
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                type="number"
                                label='Zip'
                                name='zip'
                                size='small'
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextInputField
                            label='Funding Source'
                            name='fundingSource'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AutocompleteField
                            textFieldProps={{ label: 'Purpose', size: 'small' }}
                            name='purpose'
                            options={purposeOptions}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextInputField
                            label='Loan Type'
                            name='loantype'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextInputField
                            label='Product'
                            name='product'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12} container spacing={2} columns={10}>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='Price/Value'
                                name='price'
                                size='small'
                                InputProps={{ startAdornment: '$' }}
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='Total Financed'
                                name='totalFinanced'
                                size='small'
                                InputProps={{ startAdornment: '$' }}
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='PITI'
                                name='piti'
                                size='small'
                                InputProps={{ startAdornment: '$' }}
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='Rate %'
                                name='rate'
                                size='small'
                                InputProps={{ endAdornment: '%' }}
                                type='number'
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <TextInputField
                                label='Cash To Close'
                                name='cashToClose'
                                size='small'
                                InputProps={{ startAdornment: '$' }}
                                type='number'
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={'actionBtn'} sx={{ marginTop: '20px' }}>
                        <Button
                            disabled={!isValid || !isDirty}
                            variant='contained'
                            size='medium'
                            className={'saveBtn'}
                            onClick={handleSubmit(onSaveChanges)}
                        >
                            Save Changes
                        </Button>
                        <Button variant='outlined' size='medium' className='cancelBtn'>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </FormProvider>
        </>
    )
}

export default EditLoanInfo
