import React, { useState, useRef } from 'react';
import { Box, Card, CardHeader, CardContent, IconButton, Typography, Alert, Menu, MenuItem, ListItemIcon, LinearProgress, CircularProgress } from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  MoreHoriz as MoreHorizIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon
} from '@mui/icons-material';
import cn from 'classnames'
import { EyeOffIcon, MailForwardIcon } from 'assets/svg';
import style from './styles/NoteHistory.module.scss'
import { TNote } from 'types/contact'
import { dateMDY, displayName } from 'utils'
import EditNote from './EditNote'
import apis from 'apis'
import { ConfirmDialog } from 'components'
import { useNotification, useApiMethod } from 'hooks'

type TActionProps = {
  item: TNote
  setEditId: (x: number) => void
  callback: (x: TNote, y: string) => void
}

const NoteHistoryAction = ({ item, setEditId, callback }: TActionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const confirmRef = useRef<any>(null);
  const notification = useNotification();
  const { callApi, isLoading } = useApiMethod();

  const onClickActionHandler = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickEdit = async (id: number) => {
    setEditId(id)
    setAnchorEl(null);
  }

  const onClickDelete = () => {
    setAnchorEl(null);
    confirmRef.current?.open();
  }

  const onDeleteConfirm = async () => {
    try {
      await callApi(() => apis.contact.deleteNote(item.id || 0))
      setAnchorEl(null);
      callback(item, 'delete');
    } catch {
      notification.showError('Error in deleting values!')
    }
  }

  return (
    <>
      <ConfirmDialog ref={confirmRef} title='Delete Note' content='Are you sure to delete this note ?' onConfirm={onDeleteConfirm} />
      <IconButton
        className='toggleEllipseColor'
        aria-label="more"
        id="action-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open}
        onClick={onClickActionHandler}
      >
        {isLoading ? <CircularProgress size={20} />
          : <MoreHorizIcon />}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem onClick={() => onClickEdit(item.id || 0)}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>Edit Note</Typography>
        </MenuItem>
        <MenuItem onClick={onClickDelete}>
          <ListItemIcon>
            <DeleteForeverOutlinedIcon color='error' />
          </ListItemIcon>
          <Typography variant="inherit" noWrap>Delete Note</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

type TProps = {
  notes: TNote[]
  isLoading: boolean
  callback: (x: TNote, y: string) => void
}

const NoteHistory = ({ notes, callback, isLoading }: TProps) => {
  const [editId, setEditId] = useState(0);

  return (
    <Card sx={{ border: '1px solid #DBDADE', boxShadow: 'unset' }} >
      <CardHeader
        action={
          <IconButton aria-label="settings" className='toggleEllipseColor'>
            <MoreVertIcon />
          </IconButton>
        }
        title={<Typography variant='h6' sx={{ fontWeight: '600' }}>Case Notes History</Typography>}
        disableTypography
      />
      <CardContent sx={{ p: 'unset' }}>
        {isLoading ?
          <LinearProgress />
          : (notes?.length ?
            notes.map((item: TNote, index: number) => (
              <Box key={`${index}-historyContainer`} className={cn(style.historyContainer, item.isPrivateNote ? style.rightHistoryContainer : style.leftHistoryContainer)}>
                <div className={style.messageBox}>
                  <div className={style.icon}>
                    <img src={item.isPrivateNote ? EyeOffIcon : MailForwardIcon} alt='' />
                  </div>
                  <div className={style.contentBox}>
                    <div className={style.titleBox}>
                      <span>{displayName(item.updatedBy)}</span>
                      <span className={style.date}>{dateMDY(item.createdOn)}</span>
                    </div>
                    {item.id === editId ? <EditNote item={item} onCancel={() => setEditId(0)} callback={callback} />
                      :
                      <div className={style.noteBox}>
                        <div className={style.note}>
                          <div className={style.noteText}>{item.note}</div>
                        </div>
                        <NoteHistoryAction item={item} setEditId={setEditId} callback={callback} />
                      </div>
                    }
                  </div>
                </div>
              </Box>
            ))
            :
            <Alert
              severity='info'
              sx={{
                m: '0 25px',
              }}
            >
              <strong>No History available</strong>
            </Alert>
          )}
      </CardContent>
    </Card>
  )
}

export default NoteHistory
