import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'

type TProps = {
  name: string
  label?: string
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
  sx?: any
}

const CheckboxField: React.FC<TProps> = (props) => {
  const { name, label, onChange, ...rest } = props
  const { field, fieldState } = useController({ name })
  const { error } = fieldState

  const config = {
    ...field,
    ...rest,
    error: !!error,
    helperText: error?.message,
    checked: Boolean(field.value)
  }
  if (onChange) {
    config.onChange = onChange;
  }

  return <FormControlLabel
    control={
      <Checkbox {...config} />
    }
    label={label}
  />
}

export default CheckboxField
