import dayjs from 'dayjs'

type TPriorityTypes = {
    normal: string,
    high: string,
    urgent: string
}

export const priorityTypes: TPriorityTypes = {
    normal: '!',
    high: '!!',
    urgent: '!!!'
}

export const getPriorityTypeColor = (type: string) => {
    switch (type) {
        case 'urgent':
            return 'error';
        case 'high':
            return 'warning';
        case 'normal':
            return 'default';
        default:
            return 'default';
    }
}

export const dateMDY = (date: Date | string | undefined | null) => {
    return date ? dayjs(date).format('MM/DD/YYYY') : null;
}

export const dateMDYMH = (date: Date | string | undefined | null) => {
    return date ? dayjs(date).format('MM/DD/YYYY mm:hh') : null;
}

const capitalizeFirstLetter = (string = '') => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const displayName = (name = '') => {
    const nameArray = name.split(' ');
    if (nameArray.length) {
        const lastNameInitial = nameArray[1] ? nameArray[1].slice(0, 1) + '.' : '';
        return capitalizeFirstLetter(nameArray[0]) + ' ' + lastNameInitial;
    }
    return name;
}