import React from 'react'
import cn from 'classnames'
import AppMenu from './AppMenu'
import Header from './Header'
import style from './styles/Layout.module.scss'

type TProps = {
  children: React.ReactNode
}

const Layout: React.FC<TProps> = (props) => {
  const { children } = props

  return (
    <div className={cn(style.appLayout)}>
      <AppMenu />
      <div className={cn(style.appContainer)}>
        <Header />
        <div className={cn(style.content)}>{children}</div>
      </div>
    </div>
  )
}

export default Layout
