import { useEffect, useState } from 'react';
import { Divider, Grid, LinearProgress, Typography, Button, Box } from '@mui/material';
import { WorkspacesOutlined as WorkspacesOutlinedIcon } from '@mui/icons-material';
import cn from 'classnames'
import style from '../styles/contacts.module.scss'
import TabHeader from '../TabHeader'
import { FormProvider, useForm } from 'react-hook-form';
import { ContactResourceInitialValues, TAssignedResources } from 'utils';
import { useParams } from 'react-router-dom';
import { useApiMethod, useNotification } from 'hooks';
import apis from 'apis';
import { TResource, TContactLeadResourcs } from 'types/contact';
import { TextInputField } from 'components';
import ResourceTable from './ResourceTable'
import useUserStore from 'store/userStore'

const ResourceTabs = () => {

  const { id: contactId = '' } = useParams();
  const { callApi: getResources, isLoading: cLoading } = useApiMethod();
  const [data, setData] = useState<TResource>();
  const notification = useNotification()
  const { userDetails } = useUserStore();


  const formProps = useForm({
    mode: 'all',
    defaultValues: ContactResourceInitialValues,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset
  } = formProps;

  const getResourcesData = async () => {
    try {
      if (contactId) {
        const resp = await getResources(() => apis.contact.getResources(contactId || 0, userDetails?.userId || 0));
        if (resp?.data?.contactLeadResources) {
          setData(resp?.data || []);
        }
      }
    } catch {
      // err
    }
  }

  useEffect(() => {
    getResourcesData();
  }, [contactId])

  useEffect(() => {
    if (data) {
      const values = data.contactLeadResources?.map((item: TContactLeadResourcs) => {
        const contactResource = item.contactResources?.find((itemResource: any) => (itemResource.id === item.assignedResource?.resourceId))
        return (
          {
            id: item.assignedResource?.id || 0,
            resourceTypeLookupId: item.id || 0,
            resourceId: contactResource ? { label: `${contactResource.firstName} ${contactResource.lastName}`, value: contactResource.id } : null,
            receiveStatusUpdates: item.assignedResource?.receiveStatusUpdates || false
          }
        )
      })
      reset({
        ...ContactResourceInitialValues,
        leadName: data?.contactLead?.leadName || '',
        assignedResources: values
      })
    }
  }, [data]);

  const onSaveChanges = async (values: any) => {
    const payload = {
      ...values,
      contactId: contactId || 0,
      assignedResources: values.assignedResources?.filter((item: TAssignedResources) => (!!item.resourceId))?.map((item: TAssignedResources) => (
        {
          ...item,
          resourceId: item.resourceId?.value ? +item.resourceId?.value : 0
        }
      )) || []
    }
    try {
      const respo = await apis.contact.updateResource(payload)
      notification.showSuccess(respo.data.message)
    } catch {
      notification.showError('Error in saving values!')
    }
  }

  return (
    <>
      <TabHeader />
      <Divider />
      <div className={cn(style.tabingBoxBody)}>
        <Box sx={{ width: '100%' }}>
          <Box className={cn(style.tabingBoxTabTwo)}>
            <Grid sx={{ display: 'flex', gap: 1, fontStyle: 'italic' }} alignItems='center'>
              <WorkspacesOutlinedIcon />
              <Typography color='text.primary' variant='h5' style={{ fontWeight: 600 }}>Resources</Typography>
            </Grid>
          </Box>

          <FormProvider {...formProps}>
            <Grid sx={{ display: 'flex', gap: 1, width: '30%' }} alignItems='center' textAlign={'center'}>
              <Typography color='text.primary' style={{ fontWeight: 600, width: '90%' }}>Lead/Referral Source: </Typography>
              <TextInputField
                label='Source Name'
                name='leadName'
                size='small'
              />
            </Grid>

            <Box sx={{ mt: 2 }}>
              {cLoading && <LinearProgress />}
              <ResourceTable data={data} />
            </Box>
            <Grid item xs={12} className={'actionBtn'} sx={{ marginTop: '60px' }}>
              <Button
                variant='contained'
                size='medium'
                onClick={handleSubmit(onSaveChanges)}
                disabled={isSubmitting}
              >
                Save Changes
              </Button>
              <Button variant='outlined' className='cancelBtn'>
                Cancel
              </Button>
            </Grid>
          </FormProvider>
        </Box>
      </div>
    </>
  )
}

export default ResourceTabs;