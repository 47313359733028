import { useState } from 'react';
import { IconButton } from '@mui/material';
import {
    MoreHoriz as MoreHorizIcon,
} from '@mui/icons-material';

const MyTeamAction = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onClickActionHandler = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };


    return (
        <div>
            <IconButton
                className='toggleEllipseColor'
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={onClickActionHandler}
                sx={{ padding: 'unset', backgroundColor: 'unset !important' }}
            >
                <MoreHorizIcon />
            </IconButton>
        </div>
    )
}

export default MyTeamAction;