export const API_CONSTANTS = {
  LOCAL: 'https://stagemaicrm.azurewebsites.net/api/v1',
  DEV: 'https://stagemaicrm.azurewebsites.net/api/v1',
  QA: 'https://stagemaicrm.azurewebsites.net/api/v1',
  STAGE: 'https://stagemaicrm.azurewebsites.net/api/v1',
  PRODUCTION: 'https://stagemaicrm.azurewebsites.net/api/v1',
}

export const FORM_VALIDATION_STRINGS = {
  REQUIRED: (field?: string) => `${field || 'This field'} is required.`,
  MIN_LENGTH: (item: string, len: string) => `${item} should contain minimum ${len} characters.`,
  MAX_LENGTH: (item?: string, len?: string | number) =>
    `${item} should not exceed ${len} characters.`,
  PASSWORD:
    'Password must have 1 lowercase, 1 uppercase, 1 symbol, no more than 3 same characters in a row.',
  PHONE_REGX:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  PASSWORD_REGX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?!.*(.)\1\1)/,
  NUMBER: 'Please enter a numeric value.',
  PHONE_NUMBER: 'Please enter valid phone number',
  NOT_VALID: (field?: string) => `${field} is not valid.`,
}

export const CITIZENSHIP = [
  {
    label: 'US Citizen',
    value: 'us_citizen',
  },
  {
    label: 'Permanent Resident',
    value: 'permanent_resident',
  },
  {
    label: 'Non-Permanent Resident Alien',
    value: 'non_permanent_resident_alien',
  },
  {
    label: 'Non-Resident Alien',
    value: 'non_resident_alien',
  },
]

export const US_STATES = [
  {
    label: 'Alabama',
    value: 'AL',
  },
  {
    label: 'Alaska',
    value: 'AK',
  },
  {
    label: 'American Samoa',
    value: 'AS',
  },
  {
    label: 'Arizona',
    value: 'AZ',
  },
  {
    label: 'Arkansas',
    value: 'AR',
  },
  {
    label: 'California',
    value: 'CA',
  },
  {
    label: 'Colorado',
    value: 'CO',
  },
  {
    label: 'Connecticut',
    value: 'CT',
  },
  {
    label: 'Delaware',
    value: 'DE',
  },
  {
    label: 'District Of Columbia',
    value: 'DC',
  },
  {
    label: 'Federated States Of Micronesia',
    value: 'FM',
  },
  {
    label: 'Florida',
    value: 'FL',
  },
  {
    label: 'Georgia',
    value: 'GA',
  },
  {
    label: 'Guam',
    value: 'GU',
  },
  {
    label: 'Hawaii',
    value: 'HI',
  },
  {
    label: 'Idaho',
    value: 'ID',
  },
  {
    label: 'Illinois',
    value: 'IL',
  },
  {
    label: 'Indiana',
    value: 'IN',
  },
  {
    label: 'Iowa',
    value: 'IA',
  },
  {
    label: 'Kansas',
    value: 'KS',
  },
  {
    label: 'Kentucky',
    value: 'KY',
  },
  {
    label: 'Louisiana',
    value: 'LA',
  },
  {
    label: 'Maine',
    value: 'ME',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
  },
  {
    label: 'Maryland',
    value: 'MD',
  },
  {
    label: 'Massachusetts',
    value: 'MA',
  },
  {
    label: 'Michigan',
    value: 'MI',
  },
  {
    label: 'Minnesota',
    value: 'MN',
  },
  {
    label: 'Mississippi',
    value: 'MS',
  },
  {
    label: 'Missouri',
    value: 'MO',
  },
  {
    label: 'Montana',
    value: 'MT',
  },
  {
    label: 'Nebraska',
    value: 'NE',
  },
  {
    label: 'Nevada',
    value: 'NV',
  },
  {
    label: 'New Hampshire',
    value: 'NH',
  },
  {
    label: 'New Jersey',
    value: 'NJ',
  },
  {
    label: 'New Mexico',
    value: 'NM',
  },
  {
    label: 'New York',
    value: 'NY',
  },
  {
    label: 'North Carolina',
    value: 'NC',
  },
  {
    label: 'North Dakota',
    value: 'ND',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
  },
  {
    label: 'Ohio',
    value: 'OH',
  },
  {
    label: 'Oklahoma',
    value: 'OK',
  },
  {
    label: 'Oregon',
    value: 'OR',
  },
  {
    label: 'Palau',
    value: 'PW',
  },
  {
    label: 'Pennsylvania',
    value: 'PA',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
  },
  {
    label: 'Rhode Island',
    value: 'RI',
  },
  {
    label: 'South Carolina',
    value: 'SC',
  },
  {
    label: 'South Dakota',
    value: 'SD',
  },
  {
    label: 'Tennessee',
    value: 'TN',
  },
  {
    label: 'Texas',
    value: 'TX',
  },
  {
    label: 'Utah',
    value: 'UT',
  },
  {
    label: 'Vermont',
    value: 'VT',
  },
  {
    label: 'Virgin Islands',
    value: 'VI',
  },
  {
    label: 'Virginia',
    value: 'VA',
  },
  {
    label: 'Washington',
    value: 'WA',
  },
  {
    label: 'West Virginia',
    value: 'WV',
  },
  {
    label: 'Wisconsin',
    value: 'WI',
  },
  {
    label: 'Wyoming',
    value: 'WY',
  },
]

export const BORROWING_TYPE = [
  { value: 'Occupant', label: 'Occupant' },
  { value: 'Non-Occupant', label: 'Non-Occupant' },
]

export const REL_TYPE = [
  { value: 'Married', label: 'Married' },
  { value: 'Unmarried', label: 'Unmarried' },
]