import React from 'react'
import { useNavigate } from 'react-router-dom'
import { NotificationsNoneOutlined } from '@mui/icons-material'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import Avatar from 'assets/Avatar.png'
import style from './styles/Header.module.scss'
import cn from 'classnames'
import ContactSearchDropdown from 'components/General/ContactSearchDropdown'
import { Box, Divider, Typography } from '@mui/material'
import {
  CreditCardSvg,
  InfoCircleSvg,
  LifebuoySvg,
  LogoutSvg,
  SettingsSvg,
  UserCheckSvg,
} from 'assets/svg'
import useUserStore from 'store/userStore'

const menuItemSyle = { pr: 15, m: '8px', borderRadius: '7px' }

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const { restUser, userDetails } = useUserStore()

  const menuId = 'primary-search-account-menu'

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    restUser()
    localStorage.removeItem('token')
    handleMenuClose()
    navigate('/sign-in')
  }

  const isMenuOpen = Boolean(anchorEl)
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose} sx={{ ...menuItemSyle, p: 2 }}>
        {
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <img
              src={Avatar}
              style={{
                borderRadius: '30px',
                backgroundColor: '#000',
                maxWidth: '50px',
                height: '50px',
              }}
            />
            <Box>
              <Typography variant='body1' align='center' fontWeight={'bold'}>
                {userDetails?.name || ''}
              </Typography>
              <Typography variant='body2'>{userDetails?.userTitle || ''}</Typography>
            </Box>
          </Box>
        }
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleMenuClose} sx={{ ...menuItemSyle }}>
        <img src={UserCheckSvg} style={{ marginRight: '10px' }} />
        My Profile
      </MenuItem>
      <MenuItem onClick={handleMenuClose} sx={{ ...menuItemSyle }}>
        <img src={SettingsSvg} style={{ marginRight: '10px' }} />
        Settings
      </MenuItem>
      <MenuItem onClick={handleMenuClose} sx={{ ...menuItemSyle }}>
        <img src={CreditCardSvg} style={{ marginRight: '10px' }} />
        Billing
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleMenuClose} sx={{ ...menuItemSyle }}>
        <img src={LifebuoySvg} style={{ marginRight: '10px' }} />
        Help
      </MenuItem>
      <MenuItem onClick={handleMenuClose} sx={{ ...menuItemSyle }}>
        <img src={InfoCircleSvg} style={{ marginRight: '10px' }} />
        FAQ
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogOut} sx={{ ...menuItemSyle }}>
        <img src={LogoutSvg} style={{ marginRight: '10px' }} />
        Sign Out
      </MenuItem>
    </Menu>
  )

  return (
    <div className={cn(style.headerWrap)}>
      <div className={cn(style.searchBar)}>
        <Paper component='form' sx={{ p: '2px 24px', display: 'flex', alignItems: 'center' }}>
          <ContactSearchDropdown />
          <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
            <Badge badgeContent={4} color='error'>
              <NotificationsNoneOutlined color='action' />
            </Badge>
          </IconButton>
          <IconButton
            size='large'
            edge='end'
            aria-label='account of current user'
            aria-controls={menuId}
            aria-haspopup='true'
            onClick={handleProfileMenuOpen}
            color='inherit'
          >
            <Badge
              color='success'
              variant='dot'
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <img src={Avatar} className={cn(style.accountImage)} />
            </Badge>
          </IconButton>
        </Paper>
      </div>
      {renderMenu}
    </div>
  )
}

export default Header
