import { HtmlHTMLAttributes, SyntheticEvent } from 'react'
import { useController } from 'react-hook-form'
import { UseAutocompleteProps, Autocomplete, TextField, TextFieldProps } from '@mui/material'

type TOptions = { value: string | number | boolean; label: string }
type TAutocompleteField = UseAutocompleteProps<TOptions, undefined, undefined, undefined> &
  HtmlHTMLAttributes<Element> & {
    name: string
    options: TOptions[]
    textFieldProps?: TextFieldProps
  }

const AutocompleteField = ({
  name,
  options,
  textFieldProps,
  ...autoCompleteProps
}: TAutocompleteField) => {
  const { field, fieldState } = useController({ name, defaultValue: '' })

  const handleOnChange = (e: TOptions | null) => field.onChange(e)

  const config = {
    ...field,
    ...autoCompleteProps,
    fullWidth: true,
    onChange: (_: SyntheticEvent<Element, Event>, value: TOptions | null) => handleOnChange(value),
  }

  const isError = fieldState && fieldState.error

  return (
    <Autocomplete
      {...config}
      options={options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={field?.value || null}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            {...textFieldProps}
            error={!!isError}
            helperText={isError && fieldState?.error?.message}
          />
        )
      }}
    />
  )
}

export default AutocompleteField
