import { Button, Box, } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { Add as AddIcon } from '@mui/icons-material';
import { SelectField } from 'components';
import { TTask } from 'types/contact';

type TProps = {
    callback: (x: string, y: any) => void
    toggleAdd: () => void
}

type TOrderField = 'priorityDesc' | 'priorityAsc' | 'dueDateDesc' | 'dueDateAsc';

const options = [
    { value: 'priorityDesc', label: 'Order By Priority Descending' },
    { value: 'priorityAsc', label: 'Order By Priority Ascending' },
    { value: 'dueDateDesc', label: 'Order By Date Descending' },
    { value: 'dueDateAsc', label: 'Order By Date Ascending' }
]

const orderByFields = {
    priorityDesc: { field: 'priority', orderBy: 'desc' },
    priorityAsc: { field: 'priority', orderBy: 'asc' },
    dueDateDesc: { field: 'dueDate', orderBy: 'desc' },
    dueDateAsc: { field: 'dueDate', orderBy: 'asc' }
}

const TaskSubTitle = ({ callback, toggleAdd }: TProps) => {

    const formProps = useForm({
        mode: 'all',
        defaultValues: { orderBy: 'priorityDesc' },
    })

    const handleCallback = (value: TOrderField) => {
        const order = orderByFields[value];
        callback(order.field, order.orderBy);
    }

    return (
        <Box sx={{ justifyContent: 'space-between', display: 'flex', mb: '1rem' }}>
            <FormProvider {...formProps}>
                <SelectField
                    name='orderBy'
                    options={options}
                    fullWidth={false}
                    size='small'
                    sx={{ width: '220px' }}
                    callback={handleCallback}
                />
            </FormProvider >
            <Button
                variant='outlined'
                size='medium'
                startIcon={<AddIcon />}
                onClick={toggleAdd}
            >
                Create Task
            </Button>
        </Box>
    )
}

export default TaskSubTitle
