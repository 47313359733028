import { useState, useEffect } from 'react';
import cn from 'classnames';
import { Box, Breadcrumbs, Typography, Link, Grid, Alert, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import style from 'pages/styles/ProtectedRoute.module.scss'
import { TMyTeam } from 'types/global.types';
import { DataGrid, TDataGridHeadCell } from 'components';
import { useApiMethod } from 'hooks';
import apis from 'apis'
import { UserSearchIcon, BuildingSkyscraperIcon } from 'assets/svg'
import MyTeamAction from './MyTeamAction';

const Img = styled('img')(() => ({
    cursor: 'pointer',
    height: '24px', width: '24px'
}));

const MyTeam = () => {
    const [teams, setTeams] = useState<TMyTeam[]>([]);
    const { callApi, isLoading } = useApiMethod();

    const getRecords = async () => {
        try {
            const resp = await callApi(() => apis.app.getTeams());
            setTeams(resp.data || []);
        } catch {
            // err
        }
    }

    useEffect(() => {
        getRecords();
    }, [])

    const cells: TDataGridHeadCell[] = [
        {
            label: '',
            name: 'actionsIcon',
            props: { align: 'center', sx: { width: '64px' } },
            render: () => <Img src={UserSearchIcon} alt='' />
        },
        {
            label: 'Title',
            name: 'userTitle',
        },
        {
            label: 'Company',
            name: 'companyName'
        },
        {
            label: 'Name',
            name: 'fullName'
        },
        {
            label: 'Phone',
            name: 'userPhone',
        },
        {
            label: 'Email',
            name: 'userEmail'
        },
        {
            label: 'ACTIONS',
            name: 'actions',
            render: () => (
                <MyTeamAction />
            )
        },
    ];

    return (
        <div className={cn(style.pageContainer)}>
            <div role='presentation'>
                <Breadcrumbs aria-label='breadcrumb' className={cn(style.breadcrumbsBox)}>
                    <Link underline='hover' color='inherit' href='/'>
                        Home
                    </Link>
                    <Typography color='text.primary'>My Team</Typography>
                </Breadcrumbs>
            </div>
            <div className={cn(style.contentBox)}>
                <Box className={cn(style.contentBoxPanel)} >
                    <div className={cn(style.contentBoxTitle)} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Grid sx={{ display: 'flex', gap: 1, fontStyle: 'italic', alignItems: 'center' }}>
                            <img src={BuildingSkyscraperIcon} />
                            <Typography color='text.primary' style={{ fontWeight: 600 }}>Team</Typography>
                        </Grid>
                    </div>
                    <div style={{ width: '100%', padding: '0px 26px 20px' }}>
                        {isLoading && <LinearProgress />}
                        <DataGrid
                            cells={cells}
                            data={teams}
                        />
                        {(!teams.length && !isLoading) && <Alert severity='info' >
                            <strong>No data available</strong>
                        </Alert>}
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default MyTeam