import { TUserDetails } from 'types/auth'
import { TContact } from 'types/contact'
import create from 'zustand'
import { persist } from 'zustand/middleware'

type TUserState = {
  userDetails: TUserDetails | null
  contactInfo: TContact | null
  contactStatusList: Array<any> | null
  resourceTypes: Array<any>
  isLoggedIn: boolean
  setUserDetails: (userDetails: TUserDetails) => void
  setContactInfo: (contactInfo: TContact) => void
  setContactStatusList: (contactInfo: Array<any>) => void
  setResourceTypes: (resourceTypes: Array<any>) => void
  restUser: () => void
}

const initialState = {
  userDetails: null,
  isLoggedIn: false,
  contactInfo: null,
  contactStatusList: null,
  resourceTypes: [],
}
const useUserStore = create<TUserState>()(
  persist(
    (set) => ({
      ...initialState,
      setUserDetails: (userDetails: TUserDetails) =>
        set(() => ({ userDetails: userDetails, isLoggedIn: true })),
      setContactInfo: (contactInfo: TContact) => set(() => ({ contactInfo })),
      setContactStatusList: (contactStatusList = []) => set(() => ({ contactStatusList })),
      setResourceTypes: (resourceTypes = []) => set(() => ({ resourceTypes })),
      restUser: () => set(() => ({ ...initialState })),
    }),
    {
      name: 'user',
    },
  ),
)

export default useUserStore
