import { Box, Divider, Tab, Tabs } from '@mui/material';
import {
  File
} from 'assets/svg'
import cn from 'classnames'
import style from '../styles/contacts.module.scss'
import TabPanel from 'components/General/TabPanel'
import { useState } from 'react'
import TabHeader from '../TabHeader'
import { EditLoanInfo } from './LoanInfo'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const LoanInfoTabs = () => {
  const [currentTab, setCurrentTab] = useState(0)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const tabs = [
    {
      label: 'Loan Information',
      icon: <img src={File} alt='File' />,
      component: <EditLoanInfo />
    },
  ]

  return (
    <>
      <TabHeader />
      <Divider />
      <div className={cn(style.tabingBoxBody)}>
        <Box sx={{ width: '100%' }}>
          <Box className={cn(style.tabingBoxTabTwo)}>
            <Tabs value={currentTab} onChange={handleChange} aria-label='Loan Info tabs'>
              {tabs.map((tab, index) => (
                <Tab key={index} icon={tab.icon} label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>
          <Box className={cn(style.tabingBoxPanel2)}>
            {tabs.map((tab, index) => (
              <TabPanel value={currentTab} index={index} key={index}>
                {tab.component}
              </TabPanel>
            ))}
          </Box>
        </Box>
      </div>
    </>
  )
}

export default LoanInfoTabs
