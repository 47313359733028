import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid } from '@mui/material'
import apis from 'apis'
import { AutocompleteField, DatePickerField, TextInputField, PhoneNumberField } from 'components'
import IOSSwitch from 'components/General/IOSSwitch'
import { BORROWING_TYPE, CITIZENSHIP } from 'helpers/constants'
import { useNotification } from 'hooks'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { TContact } from 'types/contact'
import { contactUpdateValidator, contactUpdateWithSpouseValidator } from 'utils'

type TProps = {
  contactInfo: TContact
  callBack: () => Promise<void>
}

const NPS = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]
const ContactDetails = ({ contactInfo, callBack }: TProps) => {
  const [isSpouse, setSpouse] = useState(!!contactInfo?.contactSpouse?.id || false)
  const notification = useNotification()

  const spouseData = contactInfo?.contactSpouse?.id
    ? {
      id: contactInfo?.contactSpouse?.id,
      contactId: contactInfo?.contactSpouse?.contactId,
      firstName: contactInfo?.contactSpouse?.firstName,
      lastName: contactInfo?.contactSpouse?.lastName,
      dateOfBirth: contactInfo?.contactSpouse?.dateOfBirth,
      citizenship: CITIZENSHIP.find((i) => i.value === contactInfo?.contactSpouse?.citizenship),
      nps: NPS.find((i) => i.value === contactInfo?.contactSpouse?.nonPurchasingSpouse),
      borrowingType: BORROWING_TYPE.find(
        (i) => i.value === contactInfo?.contactSpouse?.borrowingType,
      ),
      emailAddress: contactInfo?.contactSpouse?.emailAddress,
      phoneNumber: contactInfo?.contactSpouse?.phoneNumber,
      notes: contactInfo?.contactSpouse?.notes,
    }
    : null

  const contactUpdateIV = {
    id: contactInfo?.id,
    owner: contactInfo?.owner,
    firstName: contactInfo?.firstName,
    lastName: contactInfo?.lastName,
    dateOfBirth: contactInfo?.dateOfBirth,
    citizenship: CITIZENSHIP.find((i) => i.value === contactInfo?.citizenship),
    emailAddress: contactInfo?.emailAddress,
    phoneNumber: contactInfo?.phoneNumber,
    notes: contactInfo?.notes,
    status: contactInfo?.status,
    hasSpouse: isSpouse,
    additionalBorrowers: contactInfo?.additionalBorrowers,
    spouse: spouseData,
  }

  const formProps = useForm({
    mode: 'all',
    resolver: isSpouse
      ? yupResolver(contactUpdateWithSpouseValidator)
      : yupResolver(contactUpdateValidator),
    defaultValues: contactUpdateIV,
  })

  const {
    handleSubmit,
    reset,
    setValue,

    formState: { isValid, isDirty },
  } = formProps

  useEffect(() => {
    reset(contactUpdateIV)
  }, [contactInfo])

  const handleChange = () => {
    setSpouse(!isSpouse)
    const _isSpouse = !isSpouse
    if (!_isSpouse) {
      setValue('spouse', null, { shouldDirty: true })
    }
    if (_isSpouse && contactInfo?.contactSpouse?.id) {
      setValue('spouse', spouseData, { shouldDirty: true })
    }
  }

  const onSaveChanges = async (values: any) => {
    const payload = {
      ...values,
      citizenship: values.citizenship?.value,
      additionalBorrowers: true,
      hasSpouse: isSpouse,
      createContactSpouseRequestModel: isSpouse
        ? {
          contactSpouseId: values.spouse?.id,
          contactId: values.spouse?.contactId,
          contactSpouseFirstName: values.spouse?.firstName,
          contactSpouseLastName: values.spouse?.lastName,
          contactSpouseDateOfBirth: values.spouse?.dateOfBirth,
          contactSpouseCitizenship: values.spouse?.citizenship?.value,
          contactSpouseNonPurchasingSpouse: values.spouse.nps?.value === 'true',
          contactSpouseBorrowingType: values.spouse?.borrowingType?.value,
          contactSpouseEmailAddress: values.spouse?.emailAddress,
          contactSpousePhoneNumber: values.spouse?.phoneNumber,
          contactSpouseNotes: values.spouse?.notes,
        }
        : null,
    }
    try {
      await apis.contact.updateContact(payload)
      notification.showSuccess('Contact Details Saved.')
      callBack()
    } catch (err) {
      notification.showError('Something Went Wrong.')
    }
  }

  return (
    <>
      <FormProvider {...formProps}>
        <div>
          <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            <Grid item xs={6}>
              <TextInputField
                label='First Name'
                name='firstName'
                placeholder='First Name'
                size='small'
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputField
                label='Last Name'
                name='lastName'
                placeholder='Last Name'
                size='small'
              />
            </Grid>
            <Grid item xs={6}>
              <DatePickerField
                label='Date of Birth'
                name='dateOfBirth'
                placeholder='Date of Birth'
                datePickerProps={{ disableOpenPicker: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <AutocompleteField
                name='citizenship'
                options={CITIZENSHIP}
                textFieldProps={{ size: 'small', label: 'Citizenship' }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInputField label='Email' name='emailAddress' placeholder='Email' size='small' />
            </Grid>
            <Grid item xs={6}>
              <PhoneNumberField label='Phone' name='phoneNumber' placeholder='phone' size='small' />
            </Grid>
            <Grid item xs={12}>
              <TextInputField
                multiline
                rows={4}
                label='Note'
                name='notes'
                placeholder='Add note here...'
                size='small'
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
              Does the contact have a spouse?{' '}
              <IOSSwitch
                checked={isSpouse}
                onChange={handleChange}
                name='isSpouse'
                size='small'
                sx={{ marginLeft: '20px' }}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
              {isSpouse && (
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextInputField
                        label='First Name'
                        name='spouse.firstName'
                        placeholder='First Name'
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextInputField
                        label='Last Name'
                        name='spouse.lastName'
                        placeholder='Last Name'
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <DatePickerField
                            name='spouse.dateOfBirth'
                            placeholder='Date of Birth'
                            size='small'
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AutocompleteField
                            textFieldProps={{ size: 'small', label: 'Citizenship' }}
                            name='spouse.citizenship'
                            options={CITIZENSHIP}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <AutocompleteField
                            textFieldProps={{ size: 'small', label: 'Co-Borrower' }}
                            name='spouse.nps'
                            options={NPS}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <AutocompleteField
                            textFieldProps={{ size: 'small', label: 'Borrowing Type' }}
                            name='spouse.borrowingType'
                            options={BORROWING_TYPE}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <TextInputField
                        label='Email'
                        name='spouse.emailAddress'
                        placeholder='Email'
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <PhoneNumberField
                        label='Phone'
                        name='spouse.phoneNumber'
                        placeholder='phone'
                        size='small'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInputField
                        size='small'
                        multiline
                        rows={4}
                        label='Note'
                        name='spouse.notes'
                        placeholder='Add note here...'
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
            <Grid item xs={12} className={'actionBtn'} sx={{ marginTop: '20px' }}>
              <Button
                disabled={!isValid || !isDirty}
                variant='contained'
                size='medium'
                className={'saveBtn'}
                onClick={handleSubmit(onSaveChanges)}
              >
                Save Changes
              </Button>
              <Button variant='outlined' size='medium' className='cancelBtn'>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </FormProvider>
    </>
  )
}

export default ContactDetails
