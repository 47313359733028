import { useState, forwardRef, useImperativeHandle } from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';

/* eslint-disable  react/display-name */
export const ConfirmDialog = forwardRef((props: any, ref: any) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        open() {
            setOpen(true);
        }
    }))

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        if (props.onConfirm) {
            props.onConfirm()
        }
    };

    return (
        <Dialog
            fullWidth
            maxWidth='sm'
            open={open}
        >
            {props.title && <DialogTitle sx={{ textAlign: 'center', padding: 'unset', paddingTop: '40px', paddingBottom: '20px' }}>
                <Typography variant="h5">{props.title}</Typography>
            </DialogTitle>}
            {props.content && <DialogContent sx={{ textAlign: 'center' }}>
                <DialogContentText id='alert-dialog-description'>
                    {props.content}
                </DialogContentText>
            </DialogContent>}
            <DialogActions sx={{ justifyContent: 'center', padding: '20px' }}>
                <Button variant='contained' size='medium' onClick={handleConfirm}>Continue</Button>
                <Button variant='contained' size='medium' color="secondary" onClick={handleClose}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
});