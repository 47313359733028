import { useState, useEffect } from 'react';
import { Box, Chip, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom'
import _ from 'lodash';
import cn from 'classnames';
import { EditTask, TaskAction, TaskGridType, IsCompleteCell } from '../ContactInfo/TaskTabs/TaskUI';
import { DataGrid, TDataGridHeadCell } from 'components';
import { priorityTypes, getPriorityTypeColor, dateMDY } from 'utils';
import apis from 'apis'
import { TTask } from 'types/contact'
import { useApiMethod } from 'hooks'
import useUserStore from 'store/userStore'
import pstyle from 'pages/styles/ProtectedRoute.module.scss';
import TaskSubTitle from './TaskSubTitle';

type TRowProps = {
  priority: 'urgent' | 'high' | 'normal'
}

type TOrderBy = 'desc' | 'asc';

const priorities = ['normal', 'high', 'urgent'];

const Task = () => {
  const [gridType, setGridType] = useState('to-do')
  const [showEditTask, setShowEditTask] = useState(false)
  const [record, setRecord] = useState<TTask>()
  const [allData, setAllData] = useState<TTask[]>([]);
  const [data, setData] = useState<TTask[]>([]);
  const [todayDueTasks, setTodayDueTasks] = useState<TTask[]>([]);
  const [sortBy, setSortBy] = useState<any>({ filed: 'priority', order: 'desc' });
  const { userDetails } = useUserStore()
  const { callApi: getTaskData, isLoading: cLoading, } = useApiMethod()
  const isLoading = cLoading;
  const navigate = useNavigate();


  const filterGrid = (type: string, records: TTask[] = []) => {
    if (type === 'to-do') {
      const toDoTask = records.filter(item => (!item.isComplete)) || [];
      setData(toDoTask);
      setTodayDueTasks(toDoTask.filter(item => (item.dueDate && (dateMDY(item.dueDate) === dateMDY(new Date())))) || [])
    } else {
      setData(records.filter(item => (item.isComplete)) || []);
    }
  }

  const setOrderBy = (filed: string, orderBy: TOrderBy, records: TTask[] = []) => {
    if (filed === 'priority') {
      const data = records.sort(function (a: TTask, b: TTask) {
        return orderBy === 'asc' ? priorities.indexOf(a.priority) - priorities.indexOf(b.priority) : priorities.indexOf(b.priority) - priorities.indexOf(a.priority);
      });
      return data;
    } else {
      const data1 = _.orderBy(records, [filed], [orderBy]);
      const data = records.sort(function compare(a: TTask, b: TTask) {
        const dateA = a.dueDate ? +new Date(a.dueDate) : 0;
        const dateB = b.dueDate ? +new Date(b.dueDate) : 0;
        return orderBy === 'asc' ? (dateA - dateB) : (dateB - dateA);
      });
      return data;
    }
  }

  const getRecords = async () => {
    try {
      if (userDetails?.userId) {
        const resp = await getTaskData(() => apis.contact.getTasksByUserId());
        const records = setOrderBy(sortBy.filed, sortBy.order, resp?.data);
        setAllData(records);
        filterGrid(gridType, records);
      }
    } catch {
      // err
    }
  }

  useEffect(() => {
    getRecords();
  }, [userDetails])

  const toggleEditTask = (row?: TTask) => {
    setRecord(row)
    setShowEditTask(!showEditTask)
  }

  const toggleGridType = (type: string) => {
    setGridType(type);
    filterGrid(type, allData);
  }

  const handleChangeOrder = (filed: string, orderBy: TOrderBy) => {
    setSortBy({ filed, order: orderBy });
    const records = setOrderBy(filed, orderBy, allData);
    setAllData(records);
    filterGrid(gridType, records);
  }

  const cells: TDataGridHeadCell[] = [
    {
      label: 'Contact',
      name: 'contact',
      render: ({ row }: any) => (
        <div style={{ cursor: 'pointer' }} onClick={() => {
          navigate(`/contacts/${row.contactId}`)
        }}>
          {row.firstName} {row.lastName}
        </div>
      ),
      props: { align: 'center' }
    },
    {
      label: 'Priority',
      name: 'priority',
      render: ({ row }: { row: TRowProps }) => (
        <Chip label={priorityTypes[row.priority]} color={getPriorityTypeColor(row.priority)} sx={{ width: '47px', height: '25px' }} />
      ),
      props: { align: 'center' }
    },
    {
      label: 'Task', name: 'taskName',
      props: { align: 'center', sx: { width: '400px' } }
    },
    {
      label: 'Due Date', name: 'dueDate',
      render: ({ row }: { row: TTask }) => dateMDY(row.dueDate),
      props: { align: 'center' }
    },
    {
      label: 'Complete',
      name: 'isComplete',
      render: ({ row }) => (
        <IsCompleteCell key={row.id} row={row} callback={getRecords} />
      ),
      props: { align: 'center' }
    },
    {
      label: 'ACTIONS',
      name: 'actions',
      props: { align: 'center' },
      render: ({ row }) => (
        <TaskAction row={row} callback={getRecords} toggleEdit={toggleEditTask} />
      )
    },
  ];

  return (
    <Box sx={{ mt: '28px' }}>
      {isLoading && <LinearProgress />}
      <Box className={cn(pstyle.contentBoxPanel)} sx={{ mt: 'unset !important' }} >
        <DataGrid
          cells={cells}
          data={data}
          toolbarProps={{
            title: gridType === 'to-do' ? `You have ${todayDueTasks.length} Task Due Today` : 'Completed Tasks',
            rightIcon: <TaskGridType type={gridType} onToggle={toggleGridType} />,
            subTitle: <TaskSubTitle callback={handleChangeOrder} toggleAdd={toggleEditTask} />
          }}
        />
        {showEditTask && <EditTask
          isShow={showEditTask}
          onToggle={toggleEditTask}
          record={record}
          contactId={0}
          callback={getRecords}
          userId={userDetails?.userId}
          showContact
        />}
      </Box>
    </Box>
  )
}

export default Task
