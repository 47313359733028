import { useEffect } from 'react'
import { Grid } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm, useFormContext, useController } from 'react-hook-form'
import dayjs from 'dayjs'
import { DatePickerField, TextInputField } from 'components'
import { TLoanInformation, TLoanInformationInitialValues } from 'types/contact'
import { contactTaskValidator } from 'utils'
import apis from 'apis'

type TField = 'contractAcceptance' | 'escrowDays' | 'closingDate' | 'targetDate';

/* eslint-disable  @typescript-eslint/no-explicit-any */
type LIProps = {
    callBack: (x: any, y: TField) => void
}

const LoanInformationFields = ({ callBack }: LIProps) => {
    const { setValue, watch, getValues } = useFormContext()
    const { field, fieldState } = useController({ name: 'escrowDays' })
    const { error } = fieldState
    const contractAcceptance = watch('contractAcceptance')

    useEffect(() => {
        if (contractAcceptance && !error?.message && field.value) {
            const selectedDate = dayjs(contractAcceptance).add(+field.value, 'day').toDate();
            setValue('closingDate', selectedDate)
        }
    }, [contractAcceptance, field.value, error])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange((+e.target.value > 0 ? +e.target.value : ''));
    }

    const onChangeDate = (date: React.ChangeEvent<HTMLInputElement>, name: TField) => {
        setValue(name, date);
        setTimeout(() => {
            callBack(getValues(), name)
        }, 300)
    }

    const onBlurSaveChanges = (name: TField) => {
        setTimeout(() => {
            callBack(getValues(), name)
        }, 300)
    }

    return (
        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            <Grid item xs={12} sm={6} md={3}>
                <DatePickerField
                    label='Contract Acceptance'
                    name='contractAcceptance'
                    placeholder='Contract Acceptance'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeDate(e, 'contractAcceptance')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextInputField
                    label='Escrow Days'
                    name='escrowDays'
                    placeholder='Escrow Days'
                    size='small'
                    type='number'
                    onChange={onChange}
                    onBlur={() => onBlurSaveChanges('escrowDays')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <DatePickerField
                    label='Closing Date'
                    name='closingDate'
                    placeholder='Closing Date'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeDate(e, 'closingDate')}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <DatePickerField
                    label='Target Date'
                    name='targetDate'
                    placeholder='Target Date'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeDate(e, 'targetDate')}
                />
            </Grid>
        </Grid>
    )
}


type TProps = {
    contactId: number | string;
    loanData: TLoanInformation;
    callback: (x: TLoanInformation) => void;
}

const LoanInformation = ({ contactId, loanData, callback }: TProps) => {
    const loanInfoUpdateIV: TLoanInformationInitialValues = {
        contractAcceptance: '',
        escrowDays: null,
        closingDate: null,
        targetDate: null,
    }

    const formProps = useForm({
        mode: 'all',
        resolver: yupResolver(contactTaskValidator),
        defaultValues: loanInfoUpdateIV,
    })


    useEffect(() => {
        if (loanData) {
            const data = {
                contractAcceptance: loanData.contractAcceptance,
                escrowDays: loanData.escrowDays === 0 ? null : loanData.escrowDays,
                closingDate: loanData.closingDate,
                targetDate: loanData.targetDate,
            }
            formProps.reset(data);
        }
    }, [loanData])


    const onSaveChanges = async (values: any, field: TField) => {
        if (loanData && values[field] === loanData[field]) {
            return;
        }
        const payload = {
            ...values,
            id: loanData?.id || 0,
            contactId
        }
        try {
            const respo = await apis.contact.updateLoanInformation(payload)
            if (respo.data.success) {
                callback(respo.data.data);
            }
        } catch {
            // err
        }
    }

    return (
        <FormProvider {...formProps}>
            <LoanInformationFields callBack={onSaveChanges} />
        </FormProvider>
    )
}

export default LoanInformation
