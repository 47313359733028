import { httpInstance } from './../config/httpInstance'
import { TResource, TAssignedResource } from 'types/resource'
import { TResponseModel } from 'types/global.types'

const addResource = (payload: TResource) => httpInstance.post<TResponseModel<TResource>>('Resources', payload);

const getResources = (id: number) => httpInstance.get<TResponseModel<TResource[]>>(`Resources/${id}`);

const deleteResource = (id: number) => httpInstance.delete<TResponseModel<any>>(`Resources/${id}`);

const updateResource = (payload: TResource) => httpInstance.put<TResponseModel<TResource>>('Resources', payload);

const getAssignedResources = (id: number | string) => httpInstance.get<TResponseModel<TAssignedResource[]>>(`ContactResources/assignedresources?contactid=${id}`)

export default {
  addResource,
  getResources,
  deleteResource,
  updateResource,
  getAssignedResources
}
