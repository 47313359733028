import { Fragment, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'

import { Button, IconButton, Typography, Grid, Drawer } from '@mui/material'
import { Close } from '@mui/icons-material'

import apis from 'apis'
import cn from 'classnames'
import AutocompleteField from 'components/Form/AutocompleteField'
import DatePickerField from 'components/Form/DatePickerField'
import { TextInputField, CheckboxField } from 'components'
import { useNotification } from 'hooks'
import { FormProvider, useForm } from 'react-hook-form'
import { themeColor } from 'theme'
import { TTask, TTasInitialValues } from 'types/contact'
import { addTaskValidator, TaskInitialValues, priorityOptions, addContactTaskValidator } from 'utils'
import style from 'components/App/styles/AppMenu.module.scss'

type TProps = {
  onToggle: (x?: TTask) => void
  isShow: boolean
  record?: TTask
  contactId: number | string
  callback: () => void
  userId?: number
  showContact?: boolean
}

const EditTask = ({ onToggle, isShow, record, contactId, callback, ...rest }: TProps) => {
  const notification = useNotification()
  const [assigneeOptions, setAssigneeOptions] = useState<{ value: number, label: string }[]>([])
  const [contactOptions, setContactOptions] = useState<{ value: number, label: string }[]>([])

  const styles = {
    fieldTitle: {
      color: themeColor.light.secondaryTextColor,
      fontSize: '14px',
      fontWeight: '500',

      marginBottom: '0px',
      display: 'block',
      textTransform: 'capitalize',
    },
    mainTitle: {
      color: '#5D596C',
      fontSize: '24px',
      fontWeight: '600',
    },
  }

  const formProps = useForm({
    mode: 'all',
    resolver: yupResolver(rest.showContact ? addContactTaskValidator : addTaskValidator),
    defaultValues: TaskInitialValues,
  })

  const {
    formState: { isValid, isDirty },
    handleSubmit,
    setValue,
    watch
  } = formProps

  const selectedContact = rest.showContact ? watch('contactId') : null;
  const selectedContactId = selectedContact?.value || 0;
  const selectedAssignee = watch('assignee');

  useEffect(() => {
    if (record?.id) {
      const updatedValues = {
        ...TaskInitialValues,
        ...record,
        assignee: null,
        priority: priorityOptions.find(item => item.value === record.priority) || null
      }
      if (rest.showContact) {
        updatedValues.contactId = { value: record.contactId, label: `${record.firstName || ''} ${record.lastName || ''}` }
      }
      formProps.reset(updatedValues)
    }
  }, [record])

  const getContactList = async () => {
    try {
      const res = await apis.contact.getContactList(rest.userId);
      const options = res.data.data?.map(item => ({ value: item.id, label: `${item.firstName} ${item.lastName}` }))
      setContactOptions(options)
    } catch {
      // err
    }
  }

  const getAssigneeList = async (_contactId: number | string) => {
    if (_contactId) {
      try {
        const res = await apis.resource.getAssignedResources(_contactId);
        const options = res.data.data?.map(item => ({ value: item.id, label: `${item.firstName} ${item.lastName}` })) || []
        setAssigneeOptions(options)
        if (!options.length) {
          setValue('assignee', null);
        } else if (record?.id) {
          console.log('record---', record)
          setValue('assignee', options.find(item => item.value === record.assignee) || null);
        }
      } catch {
        // err
      }
    } else if (selectedAssignee?.value) {
      setValue('assignee', null);
    }
  }

  useEffect(() => {
    if (rest.showContact) {
      getContactList()
    }
  }, [rest.userId])

  useEffect(() => {
    if (rest.showContact) {
      getAssigneeList(selectedContactId)
    }
  }, [selectedContactId])

  useEffect(() => {
    if (contactId) {
      getAssigneeList(contactId);
    }
  }, [contactId])

  const submitHandler = async (values: TTasInitialValues) => {
    const payload = {
      ...values,
      assignee: values.assignee?.value || 0,
      priority: values.priority?.value || '',
    }
    if (rest.showContact) {
      payload.contactId = values.contactId?.value || 0
    } else {
      payload.contactId = contactId ? contactId : record?.contactId
    }

    try {
      const respo = await apis.contact.updateTask(payload)
      notification.showSuccess(respo.data.message)
      setTimeout(() => {
        callback()
        onToggle()
      }, 300)
    } catch {
      notification.showError('Error in saving values!')
    }
  }

  return (
    <Fragment key={'right'}>
      <Drawer
        anchor='right'
        PaperProps={{ style: { borderRadius: '20px 0 0 20px' } }}
        open={isShow}
        sx={{ borderRadius: '34px' }}
      >
        <div className={cn(style.dialogInner)}>
          <IconButton
            type='button'
            size='medium'
            sx={{ p: '0px', position: 'absolute', right: '15px' }}
            onClick={() => onToggle()}
          >
            <Close color='action' style={{ color: 'gray' }} fontSize='small' />
          </IconButton>

          <div className={cn(style.createForm)}>
            <FormProvider {...formProps}>
              <div className={cn(style.form)}>
                <Grid container spacing={2}>
                  <Grid item sm={12} sx={{
                    paddingTop: '30px',
                    paddingBottom: '16px',
                  }}>
                    <Typography
                      sx={{ ...styles.mainTitle }}
                      variant='h6'
                      className={cn(style.Title1)}
                    >
                      {record?.id ? 'Edit Task' : 'Add Task'}
                    </Typography>
                    <Typography className={cn(style.Title1)}>
                      {/* Enter the information below to create a new contact in your database. */}
                    </Typography>
                  </Grid>
                  {rest.showContact && <Grid item sm={12}>
                    <AutocompleteField
                      textFieldProps={{ label: 'Contact', size: 'small' }}
                      name='contactId'
                      options={contactOptions}
                    />
                  </Grid>}
                  <Grid item sm={12}>
                    <TextInputField label='Task' name='taskName' size='small' />
                  </Grid>
                  <Grid item sm={12}>
                    <AutocompleteField
                      textFieldProps={{ label: 'Assignee', size: 'small' }}
                      name='assignee'
                      options={assigneeOptions}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <DatePickerField
                      label='Due Date'
                      name='dueDate'
                      placeholder='Due Date'
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <AutocompleteField
                      textFieldProps={{ label: 'Priority', size: 'small' }}
                      name='priority'
                      options={priorityOptions}
                    />
                  </Grid>
                  {record?.id && <Grid item sm={12}>
                    <CheckboxField name='isComplete' label='Is Complete' />
                  </Grid>}
                  <Grid item sm={12}>
                    <Button
                      disabled={!isDirty || !isValid}
                      variant='contained'
                      size='medium'
                      fullWidth
                      onClick={handleSubmit(submitHandler)}
                    >
                      Save Task
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </FormProvider>
          </div>
        </div>
      </Drawer>
    </Fragment>
  )
}

export default EditTask
