import { toast } from 'material-react-toastify'
const useNotification = () => {
  const options = {}

  const showSuccess = (message: string | React.ReactNode) => toast.success(message, options)
  const showInfo = (message: string | React.ReactNode) => toast.info(message, options)
  const showWarrnig = (message: string | React.ReactNode) => toast.warn(message, options)
  const showError = (message: string | React.ReactNode) => toast.error(message, options)

  return {
    showError,
    showWarrnig,
    showSuccess,
    showInfo,
  }
}

export default useNotification
