import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type TProps = {
    type: string
    onToggle: (x: string) => void
}

const TaskGridType = ({ type, onToggle }: TProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickActionHandler = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClickMenuItem = () => {
        onToggle(type === 'to-do' ? 'complete' : 'to-do')
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                className='toggleEllipseColor'
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={onClickActionHandler}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    onClick={onClickMenuItem}
                    sx={{ minWidth: '150px' }}
                >
                    <Typography variant="inherit" noWrap>{type === 'to-do' ? 'Completed Tasks' : 'To-Do Task'}</Typography>
                </MenuItem>
            </Menu>
        </>
    );
}

export default TaskGridType;