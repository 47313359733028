import React from 'react';
import cn from 'classnames';
import pstyle from 'pages/styles/ProtectedRoute.module.scss';
import CardTotal from './CardTotal';
import Task from './Task';

type TProps = {
  name?: string
}

const Dashboard: React.FC<TProps> = () => {
  return (
    <div className={cn(pstyle.pageContainer)}>
      <div className={cn(pstyle.contentBox)} style={{ marginTop: '10px' }}>
        <CardTotal />
        <Task />
      </div>
    </div >
  )
}

export default Dashboard
