import { TContactInitialValues, TTasInitialValues, TGoalInitialValues, TNote, TLoanInfoInitialValues } from 'types/contact'

export const ContactInitialValues: TContactInitialValues = {
  status: null,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  dob: null,
  citizenship: null,
  note: '',
}

export const TaskInitialValues: TTasInitialValues = {
  contactId: null,
  taskName: '',
  assignee: null,
  dueDate: null,
  priority: null,
  isComplete: false,
}

export const GoalInitialValues: TGoalInitialValues = {
  purpose: null,
  primaryFocus: null,
  loanRetentionPeriod: '',
  priceRange: '',
  paymentRange: '',
  cashOutRange: '',
  urgency: '',
  homeType: '',
  loanType: '',
  painPoint: '',
}

export const NoteInitialValues: TNote = {
  note: '',
  type: 'privateNote',
  isPrivateNote: true,
}

// export const ContactResourceInitialValues: TResource = {
//   contactLead: {},
//   contactLeadResources: []
// }

export type TAssignedResources = {
  id: number,
  resourceTypeLookupId: number,
  resourceId?: null | { label: string, value: string | number },
  receiveStatusUpdates: boolean
}


export type TContactResourceInitialValues = {
  contactId?: number,
  leadName: string,
  assignedResources?: TAssignedResources[]
}

export const ContactResourceInitialValues: TContactResourceInitialValues = {
  leadName: '',
  assignedResources: []
}

export const LoanInfoInitialValues: TLoanInfoInitialValues = {
  loanNumber: '',
  escrowNumber: '',
  subjectAddress: '',
  city: '',
  country: '',
  state: '',
  zip: '',
  fundingSource: '',
  purpose: null,
  loantype: '',
  product: '',
  price: '',
  totalFinanced: '',
  piti: '',
  rate: '',
  cashToClose: '',
}