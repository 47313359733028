import { FC, useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { AutocompleteField, DatePickerField, TextInputField } from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { TAddressHistory } from 'types/contact'
import apis from 'apis'
import { useApiMethod, useNotification } from 'hooks'
import { US_STATES } from 'helpers/constants'
import { calcDuration } from 'helpers/utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { addressHistoryValidator } from 'utils'

type TProps = {
  data: TAddressHistory[]
  contactId: number
  callBack: () => Promise<void>
}

const ADDRESS_TYPE = [
  { label: 'Mortgages', value: 'Mortgages' },
  { label: 'Rent', value: 'Rent' },
]
const AddressHistory: FC<TProps> = ({ data, contactId, callBack }) => {
  const notification = useNotification()
  const { callApi: saveAddress, isLoading } = useApiMethod()

  const v = data.find((a) => a.primaryAddress)
  const p = data.find((a) => a.primaryAddress === false)
  const value = {
    ...v,

    state: US_STATES.find((s) => s.value === v?.state),
    addressHistoryType: ADDRESS_TYPE.find((a) => a.value === v?.addressHistoryType),
    prior: {
      ...p,
      state: US_STATES.find((s) => s.value === p?.state),
      addressHistoryType: ADDRESS_TYPE.find((a) => a.value === p?.addressHistoryType),
    },
  }

  const formProps = useForm({
    mode: 'all',
    resolver: yupResolver(addressHistoryValidator),
    defaultValues: value,
  })

  const {
    formState: { isValid, isDirty },
    handleSubmit,
    reset,
  } = formProps

  useEffect(() => {
    reset(value)
  }, [data])

  const onSaveChanges = async (values: any) => {
    const payload: TAddressHistory[] = []

    const p = { ...values.prior }

    delete values.prior

    payload.push({
      contactId,
      primaryAddress: true,
      id: values.id || 0,
      addressHistoryType: values?.addressHistoryType?.value || null,
      monthlyAmount: values.monthlyAmount || null,
      moveInDate: values.moveInDate || null,
      moveOutDate: values.moveOutDate || null,
      street: values.street || null,
      city: values.city || null,
      state: values.state?.value || null,
      zip: values.zip || null,
    })

    payload.push({
      contactId,
      primaryAddress: false,
      id: p.id || 0,
      addressHistoryType: p?.addressHistoryType?.value || null,
      monthlyAmount: p.monthlyAmount || null,
      moveInDate: p.moveInDate || null,
      moveOutDate: p.moveOutDate || null,
      street: p.street || null,
      city: p.city || null,
      state: p.state?.value || null,
      zip: p.zip || null,
    })

    try {
      await saveAddress(() => apis.contact.saveContactAddressHistories(payload))
      notification.showSuccess('Address Saved.')
      callBack()
    } catch (err) {
      notification.showError('Something Went Wrong.')
    }
  }
  return (
    <>
      <FormProvider {...formProps}>
        <Typography variant='h6' sx={{ marginY: '20px' }}>
          Primary Address
          {value?.moveInDate && (
            <span style={{ opacity: '.6', marginLeft: '10px' }}>
              -{'  '}
              {calcDuration(new Date(value?.moveInDate), new Date(value?.moveOutDate || ''))}
            </span>
          )}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AutocompleteField
                textFieldProps={{ size: 'small', label: 'Type' }}
                name='addressHistoryType'
                options={ADDRESS_TYPE}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInputField
                label='Rent/Mortgages Amount'
                name='monthlyAmount'
                size='small'
                InputProps={{ startAdornment: '$' }}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField label='Move-in Date' name='moveInDate' size='small' />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField label='Move-out Date' name='moveOutDate' size='small' />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item flex={1}>
              <TextInputField label='Street' name='street' size='small' />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInputField label='City' name='city' size='small' />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteField
                textFieldProps={{ size: 'small', label: 'State' }}
                name='state'
                options={US_STATES}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInputField type='number' label='Zip' name='zip' size='small' />
            </Grid>
          </Grid>
        </Box>
        <Typography variant='h6' sx={{ marginTop: '50px', marginBottom: '20px' }}>
          Prior Address
          {value.prior?.moveInDate && (
            <span style={{ opacity: '.6', marginLeft: '10px' }}>
              -{'  '}
              {calcDuration(
                new Date(value.prior?.moveInDate),
                new Date(value.prior?.moveOutDate || ''),
              )}
            </span>
          )}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AutocompleteField
                textFieldProps={{ size: 'small', label: 'Type' }}
                name='prior.addressHistoryType'
                options={ADDRESS_TYPE}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInputField
                label='Rent/Mortgages Amount'
                name='prior.monthlyAmount'
                size='small'
                InputProps={{ startAdornment: '$' }}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField label='Move-in Date' name='prior.moveInDate' size='small' />
            </Grid>
            <Grid item xs={3}>
              <DatePickerField label='Move-out Date' name='prior.moveOutDate' size='small' />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item flex={1}>
              <TextInputField label='Street' name='prior.street' size='small' />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextInputField label='City' name='prior.city' size='small' />
            </Grid>
            <Grid item xs={4}>
              <AutocompleteField
                textFieldProps={{ size: 'small', label: 'State' }}
                name='prior.state'
                options={US_STATES}
              />
            </Grid>
            <Grid item xs={4}>
              <TextInputField type='number' label='Zip' name='prior.zip' size='small' />
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} sx={{ marginTop: '40px' }} className='actionBtn'>
          <LoadingButton
            onClick={handleSubmit(onSaveChanges)}
            loading={isLoading}
            disabled={!isValid || !isDirty}
            variant='contained'
            size='large'
            className='saveBtn'
          >
            Save Changes
          </LoadingButton>
          <Button variant='text' size='large' className='cancelBtn'>
            Cancel
          </Button>
        </Grid>
      </FormProvider>
    </>
  )
}

export default AddressHistory
