import * as dateFns from 'date-fns'

export const getBuildENV = () => {
  const buildENV = process.env.REACT_APP_BUILD_ENV

  if (buildENV === 'QA') {
    return 'QA'
  }

  if (buildENV === 'DEV') {
    return 'DEV'
  }

  if (buildENV === 'STAGE') {
    return 'STAGE'
  }

  if (buildENV === 'PRODUCTION') {
    return 'PRODUCTION'
  }

  return 'LOCAL'
}

// export const calcDuration = (startDate: Date, endDate = new Date()) => {
//   const diff = Math.floor(startDate.getTime() - endDate.getTime())
//   const day = 1000 * 60 * 60 * 24

//   const days = Math.floor(diff / day)
//   const months = Math.floor(days / 31)
//   const years = Math.floor(months / 12)

//   let message = ''
//   if (days && !years) {
//     days + ' days '
//   }
//   if (months) {
//     message += months + ' months '
//   }
//   if (years) {
//     message += years + ' years'
//   }

//   return message
// }

export const calcDuration = (startDate: Date, endDate = new Date()) => {
  const duration = dateFns.intervalToDuration({
    start: startDate,
    end: endDate,
  })

  let [years, months, days] = ['', '', '']

  if (duration?.years && duration?.years > 0) {
    years = duration.years === 1 ? '1 year' : `${duration.years} years`
  }
  if (duration?.months && duration?.months > 0) {
    months = duration.months === 1 ? '1 month' : `${duration.months} months`
  }
  if (duration?.days && duration?.days > 0) {
    days = duration.days === 1 ? '1 day' : `${duration.days} days`
  }

  const response = [years, months, days].filter(Boolean)

  switch (response.length) {
    case 3:
      response[1] += ' &'
      response[0] += ','
      break
    case 2:
      response[0] += ' &'
      break
  }
  return response.join(' ')
}
