import { useToggle } from '@mantine/hooks'
import {
  BadgeOutlined,
  DashboardOutlined,
  HeadphonesOutlined,
  MenuOpenRounded,
  PeopleOutline,
  PersonOutlineOutlined,
  SignalCellularAltOutlined,
  ToggleOffOutlined,
} from '@mui/icons-material'
import AddSharpIcon from '@mui/icons-material/AddSharp'
import { Button, IconButton } from '@mui/material'
import blueLogo from 'assets/logo-blue.png'
import cn from 'classnames'
import { useLocation, useNavigate } from 'react-router-dom'
import ContactForm from './ContactForm'
import style from './styles/AppMenu.module.scss'
import { BuildingSkyscraperIcon } from 'assets/svg'

const menuList = [
  {
    icon: <DashboardOutlined />,
    title: 'Dashboard',
    route: '/',
  },
  {
    icon: <PeopleOutline />,
    title: 'Contacts',
    route: '/contacts',
  },
  {
    icon: <BadgeOutlined />,
    title: 'Resources',
    route: '/resources',
  },
  {
    icon: <img src={BuildingSkyscraperIcon} width={21} height={21} />,
    title: 'My Team',
    route: '/my-team',
  },
  {
    icon: <SignalCellularAltOutlined />,
    title: 'Goals',
    route: '/goals',
  },
]

const menuListSetting = [
  {
    icon: <PersonOutlineOutlined />,
    title: 'Profile',
    route: '/profile',
  },
  {
    icon: <HeadphonesOutlined />,
    title: 'Support',
    route: '/support',
  },
  {
    icon: <ToggleOffOutlined />,
    title: 'Page Settings',
    route: '/page-settings',
  },
]
const AppMenu = () => {
  const { pathname } = useLocation()
  const [showContactForm, toggleContactForm] = useToggle()
  const [fullMenu, toggleFullMenu] = useToggle()
  const navigate = useNavigate()

  return (
    <div className={cn(style.appMenu, !fullMenu && style.menuOpen)}>
      <div className={cn(style.appMenuHeader)}>
        <img className={style.appLogo} src={blueLogo} />

        <IconButton onClick={() => toggleFullMenu()}>
          <MenuOpenRounded />
        </IconButton>
      </div>
      <div className={cn(style.topMenu)}>
        <div className={cn(style.menuList)}>
          <span className={cn(style.menuLabel)}>Menu</span>
          {menuList.map((menu, index) => (
            <div
              className={cn(style.menuItem, pathname === menu.route && style.selected)}
              key={index}
              onClick={() => navigate(menu.route)}
            >
              <div className={cn(style.icon)}>{menu.icon}</div>
              <div className={cn(style.title)}>{menu.title}</div>
            </div>
          ))}
        </div>
        <Button
          variant='contained'
          className={cn(style.addContact)}
          fullWidth
          onClick={() => toggleContactForm()}
          startIcon={<AddSharpIcon className={cn(style.addIcon)} />}
        >
          New Contact
        </Button>
      </div>
      <div className={cn(style.menuListSetting)}>
        {menuListSetting.map((menu, index) => (
          <div
            className={cn(style.menuItem, pathname === menu.route && style.selected)}
            key={index}
            onClick={() => navigate(menu.route)}
          >
            <div className={cn(style.icon)}>{menu.icon}</div>
            <div className={cn(style.title)}>{menu.title}</div>
          </div>
        ))}
      </div>
      <ContactForm showContactForm={showContactForm} toggleContactForm={toggleContactForm} />
    </div>
  )
}

export default AppMenu
