import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Grid, LinearProgress, Typography } from '@mui/material';
import { ToggleIcon, UserPlusLightIcon, ArrowUpIcon, ArrowDownIcon, AwardIcon, FileDollarIcon, WaveSawToolIcon } from 'assets/svg';
import style from './Dashboard.module.scss';
import apis from 'apis';
import { useApiMethod } from 'hooks'
import { TDashboard } from 'types/global.types'

type TProps = {
    name?: string
}

const mockData = [
    {
        title: 'Active Originations',
        icon: WaveSawToolIcon,
        count: 3,
        type: 'high',
        percentage: '40%',
        compare: 'Compared to Sep 2022'
    },
    {
        title: 'Active Escrow Volume',
        icon: FileDollarIcon,
        count: '$1,090',
        type: 'low',
        percentage: '-21%',
        compare: 'Compared to Sep 2022'
    },
    {
        title: 'Deals Close',
        icon: AwardIcon,
        count: 2,
        type: 'low',
        percentage: '-75%',
        compare: 'Compared to Sep 2022'
    }
];

const initilaValues = {
    newLeadCount: 0,
    newLeadPercentage: 0
}

type TGridItem = {
    title: string,
    icon: any,
    count: number,
    type: string,
    percentage: number,
    compare: string
}

const GridItem = (props: TGridItem) => {
    return (
        <Grid item sm={12} md={6} lg={3}>
            <div className={style.totalBox}>
                <div className={style.titleBox}>
                    <Typography className={style.title}>New Leads</Typography>
                    <img src={ToggleIcon} alt='' />
                </div>
                <div className={style.stateBox}>
                    <div className={style.left}>
                        <img className={style.icon} src={UserPlusLightIcon} alt='' />
                        <Typography className={style.text}>{props.count}</Typography>
                    </div>
                    <div className={style.right}>
                        <img className={style.icon} src={props.type === 'high' ? ArrowUpIcon : ArrowDownIcon} alt='' />
                        <Typography className={cn(style.text, props.type === 'high' ? style.green : style.red)}>{props.percentage}%</Typography>
                    </div>
                </div>
                <div className={style.compareBox}>Compared to Sep 2022</div>
            </div>
        </Grid>
    )
}

const CardTotal: React.FC<TProps> = () => {
    const [data, setData] = useState<TDashboard>(initilaValues);
    const { callApi, isLoading } = useApiMethod();

    const getDashboardData = async () => {
        const respo = await callApi(() => apis.app.dashboards());
        setData(respo?.data);
    }

    useEffect(() => {
        getDashboardData()
    }, [])

    if (isLoading) {
        return <LinearProgress />
    }

    return (
        <Grid container spacing={2}>
            <GridItem
                title='New Leads'
                icon={UserPlusLightIcon}
                count={data.newLeadCount}
                type='high'
                percentage={data.newLeadPercentage}
                compare='Compared to Sep 2022'
            />
            {mockData.map((item: any) => (
                <Grid item sm={12} md={6} lg={3} key={item.title}>
                    <div className={style.totalBox}>
                        <div className={style.titleBox}>
                            <Typography className={style.title}>{item.title}</Typography>
                            <img src={ToggleIcon} alt='' />
                        </div>
                        <div className={style.stateBox}>
                            <div className={style.left}>
                                <img className={style.icon} src={item.icon} alt='' />
                                <Typography className={style.text}>{item.count}</Typography>
                            </div>
                            <div className={style.right}>
                                <img className={style.icon} src={item.type === 'high' ? ArrowUpIcon : ArrowDownIcon} alt='' />
                                <Typography className={cn(style.text, item.type === 'high' ? style.green : style.red)}>{item.percentage}</Typography>
                            </div>
                        </div>
                        <div className={style.compareBox}>{item.compare}</div>
                    </div>
                </Grid>
            ))}
        </Grid>
    )
}

export default CardTotal