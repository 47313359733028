import { TResourceInitialValues } from 'types/resource'

export const ResourceInitialValues: TResourceInitialValues = {
    companyName: '',
    resourceTypeLookupId: null,
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    assistantFirstName: '',
    assistantLastName: '',
    assistantPhone: '',
    assistantEmail: '',
}