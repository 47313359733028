import { httpInstance } from './../config/httpInstance'
import { TAdditionalBorrower, TAddressHistory, TContact, TContactStatus, TLoanInformation, TLoanCheckList, TLoanChecklistTask, TInterestRateLockTracker, TTask, TGoal, TNote, TResource } from 'types/contact'
import { TResponseModel } from 'types/global.types'
import { TContactResourceInitialValues } from 'utils'

const statusList = () => httpInstance.get<TContactStatus>('/Commons/GetAllContactStatus')

const addNewContact = (payload: any) => httpInstance.post('Contacts', payload)

const updateContact = (payload: any) => httpInstance.put('Contacts', payload)

const getContactList = (id = 0) =>
  httpInstance.get<TResponseModel<TContact[]>>(`/contacts?id=${id}`)

const getContactDetailsByid = (id: string | number) =>
  httpInstance.get<TResponseModel<TContact>>(`/contacts/${id}`)

const updateStatus = (payload: { contactId: number; statusId: number }) =>
  httpInstance.put<TResponseModel<TContact>>('/Contacts/UpdateStatus', payload)

const saveContactAddressHistories = (payload: TAddressHistory[]) =>
  httpInstance.post<TResponseModel<TContact>>('/ContactAddressHistories', payload)

const saveAdditionalBorrowers = (payload: TAdditionalBorrower[]) =>
  httpInstance.post<TResponseModel<TAdditionalBorrower>>('/AdditionalBorrowers', payload)

const getLoanInformation = (id: string | number) => httpInstance.get<TResponseModel<TLoanInformation>>(`LoanInformations?contactid=${id}`)

const updateLoanInformation = (payload: TLoanInformation) => httpInstance.put<TResponseModel<TLoanInformation>>('LoanInformations', payload)

const updateLoanChecklist = (payload: TLoanCheckList) => httpInstance.put<TResponseModel<TLoanInformation>>('LoanInformations/UpdateLoanChecklist', payload)

const updateTaskStatus = (payload: TLoanChecklistTask) => httpInstance.put<TResponseModel<TLoanInformation>>('LoanInformations/UpdateTaskStatus', payload)

const updateInterestRateLockTracker = (payload: TInterestRateLockTracker) => httpInstance.put<TResponseModel<TLoanInformation>>('LoanInformations/UpdateInterestRateLockTracker', payload)

const addTask = (payload: TTask) => httpInstance.post<TResponseModel<TTask>>('Tasks', payload)

const getTasks = (id: string | number) => httpInstance.get<TResponseModel<TTask[]>>(`Tasks?contactid=${id}`)

const updateTask = (payload: TTask) => httpInstance.put<TResponseModel<TTask>>('Tasks', payload)

const deleteTask = (id: string | number) => httpInstance.delete<TResponseModel<any>>(`Tasks/${id}`)

const addGoal = (payload: TGoal) => httpInstance.post<TResponseModel<TGoal>>('ContactGoals', payload)

const getGoals = (id: string | number) => httpInstance.get<TResponseModel<TGoal>>(`ContactGoals?contactId=${id}`)

const updateGoal = (payload: TGoal) => httpInstance.put<TResponseModel<TGoal>>('ContactGoals', payload)

const addNote = (payload: TNote) => httpInstance.post<TResponseModel<TNote>>('ContactNotes', payload)

const getNotes = (id: string | number) => httpInstance.get<TResponseModel<TNote[]>>(`ContactNotes?contactId=${id}`)

const updateNote = (payload: TNote) => httpInstance.put<TResponseModel<TNote>>('ContactNotes', payload)

const deleteNote = (id: string | number) => httpInstance.delete<TResponseModel<any>>(`ContactNotes/${id}`)

const getTasksByUserId = () => httpInstance.get<TResponseModel<TTask[]>>('Tasks/GetByUserId')

const getResources = (id: string | number, resourceOwner: string | number) => httpInstance.get<TResponseModel<TResource>>(`ContactResources?contactid=${id}&resourceOwner=${resourceOwner}`)

const updateResource = (payload: TContactResourceInitialValues) => httpInstance.put<TResponseModel<TNote>>('ContactResources', payload)

export default {
  statusList,
  addNewContact,
  getContactList,
  getContactDetailsByid,
  updateContact,
  updateStatus,
  saveContactAddressHistories,
  saveAdditionalBorrowers,
  getLoanInformation,
  updateLoanInformation,
  updateLoanChecklist,
  updateTaskStatus,
  updateInterestRateLockTracker,
  getTasks,
  addTask,
  updateTask,
  deleteTask,
  addGoal,
  getGoals,
  updateGoal,
  addNote,
  getNotes,
  updateNote,
  deleteNote,
  getTasksByUserId,
  getResources,
  updateResource,
}
