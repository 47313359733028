import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'

type TProps = {
  name: string
  placeholder?: string
  type?: string
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
} & TextFieldProps

const TextInputField: React.FC<TProps> = (props) => {
  const { name, placeholder = '', type, onChange, ...rest } = props
  const { field, fieldState } = useController({ name, defaultValue: '' })
  const { error } = fieldState

  const config = {
    ...field,
    ...rest,
    placeholder,
    type,
    error: !!error,
    helperText: error?.message,
    InputProps: {
      ...rest.InputProps,
      startAdornment: field.value && rest?.InputProps?.startAdornment,
      endAdornment: field.value && rest?.InputProps?.endAdornment,
    },
  }
  if (onChange) {
    config.onChange = onChange;
  }
  return <TextField fullWidth {...config} />
}

export default TextInputField
