import { Button } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { contactNoteValidator, NoteInitialValues } from 'utils'
import { TNote } from 'types/contact'
import { useNotification } from 'hooks'
import apis from 'apis'
import style from './styles/NoteHistory.module.scss'
import { TextInputField } from 'components'
import useUserStore from 'store/userStore'

type TProps = {
    item: TNote
    onCancel: () => void
    callback: (x: TNote, y: string) => void
}

const EditNote = ({ item, onCancel, callback }: TProps) => {
    const notification = useNotification()
    const { userDetails } = useUserStore()

    const formProps = useForm({
        mode: 'all',
        resolver: yupResolver(contactNoteValidator),
        defaultValues: { ...NoteInitialValues, ...item },
    })

    const {
        handleSubmit,
        formState: { isValid, isDirty }
    } = formProps

    const onSaveChanges = async (values: TNote) => {
        try {
            const payload = {
                ...values,
                updatedBy: userDetails?.name
            }
            const respo = await apis.contact.updateNote(payload)
            notification.showSuccess(respo.data.message);
            onCancel();
            callback(respo.data.data, 'edit');
        } catch {
            notification.showError('Error in saving values!')
        }
    }
    return (
        <FormProvider {...formProps}>
            <div className={style.noteBox}>
                <TextInputField
                    size='small'
                    multiline
                    rows={4}
                    name='note'
                    className={style.textarea}
                    focused
                />
                <Button
                    disabled={!isValid || !isDirty}
                    variant='contained'
                    size='medium'
                    className={style.saveButton}
                    onClick={handleSubmit(onSaveChanges)}
                >
                    Save
                </Button>
                <Button
                    variant='contained'
                    size='medium'
                    className={style.cancelbutton}
                    color='inherit'
                    onClick={onCancel}
                >
                    Cancel
                </Button>
            </div>
        </FormProvider>
    )
}


export default EditNote
