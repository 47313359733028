import { createTheme } from '@mui/material'

export const themeColor = {
  light: {
    background: '#ffffff',
    backgroundShade: '#f9f9f9',
    primaryMain: '#27aae1',
    primaryHighlight: '#39c5ff',
    primaryTextColor: '#060606',
    secondaryTextColor: '#666',
    borderColor: '#0000005b',
  },
  dark: {
    background: '#1C1C1C',
    backgroundShade: '#28282a',
    primaryMain: '#27aae1',
    primaryHighlight: '#39c5ff',
    primaryTextColor: '#FFF',
    secondaryTextColor: '#AAA',
    borderColor: '#ffffff3b',
  },
}

export const getTheme = (mode: 'light' | 'dark') => {
  const theme = createTheme({
    palette: {
      mode: mode,
      background: {
        paper: themeColor[mode].background,
        default: themeColor[mode].background,
      },
      primary: {
        main: themeColor[mode].primaryMain,
        contrastText: themeColor[mode].background,
      },
      secondary: {
        main: '#CC2936',
        dark: '#FF717D',
        contrastText: themeColor[mode].background,
      },
      text: {
        primary: themeColor[mode].primaryTextColor,
        secondary: themeColor[mode].secondaryTextColor,
      },
      action: {
        active: '#1D212A',
      },
      error: {
        main: '#CC2936',
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            '& .MuiButton-contained': {
              background: 'linear-gradient(72.47deg, #26abe2 22.16%, #3dbbee 76.47%)',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            '& .MuiPaper-root': {
              backgroundColor: themeColor[mode].background,
              color: themeColor[mode].primaryTextColor,
            },
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            backgroundColor: themeColor[mode].backgroundShade,
            backgroundImage: 'unset',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused:not(.Mui-error)': { color: themeColor[mode].primaryHighlight },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
              borderColor: themeColor[mode].primaryHighlight,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: '0',
          },
        },
      },
    },

    typography: {
      fontSize: 12.25,
      fontFamily: [
        'Public Sans',
        'Poppins',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  })

  return theme
}
