import { useState, useEffect } from 'react'
import { List, ListItem, ListItemAvatar, ListItemText, Typography, Avatar, Menu, MenuItem } from '@mui/material'
import Grid from '@mui/system/Unstable_Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import {
    CheckIcon,
    CloseWhite,
    DeviceMobile,
    Mail,
    User,
    UserPlus,
    Users,
} from 'assets/svg'
import cn from 'classnames'
import style from './styles/contacts.module.scss'
import apis from 'apis'
import Dot from 'components/App/Dot'
import { useApiMethod } from 'hooks'
import useUserStore from 'store/userStore'

type TStatus = { value: string | number | boolean; color: string; label: string }
const TabHeader = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const { contactInfo, setContactInfo, contactStatusList, setContactStatusList } = useUserStore()

    const { callApi: updateStatusApi, isLoading: statusLoading } = useApiMethod()

    const statusOptions = contactStatusList?.map((i) => {
        return {
            value: i.id,
            label: i.status,
            color:
                i.id == 1
                    ? '#c3c3c3'
                    : i.id == 2
                        ? '#ffec3f'
                        : i.id == 3
                            ? '#ffa726'
                            : i.id == 4
                                ? '#3ebcef'
                                : i.id == 5
                                    ? '#ea5455'
                                    : i.id == 6
                                        ? '#53d28c'
                                        : i.id == 7
                                            ? '#7367f0'
                                            : i.id == 8
                                                ? '#870a0a'
                                                : '#333333',
        }
    })
    const status = statusOptions?.find((i) => i.value == contactInfo?.status)

    const getStatusList = async () => {
        try {
            if (!contactStatusList?.length) {
                const res = await apis.contact.statusList()
                if (res.data.data) {
                    setContactStatusList(res.data.data)
                }
            }
        } catch {
            // err
        }
    }

    useEffect(() => {
        getStatusList();
    }, [])

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleStatusChange = async (contactId: number, status: TStatus) => {
        handleMenuClose()
        const payload = {
            contactId: contactId,
            statusId: +status.value,
        }
        try {
            const res = await updateStatusApi(() => apis.contact.updateStatus(payload))
            setContactInfo({ ...res.data, ...contactInfo, status: res.data.status })
        } catch {
            // err
        }
    }

    if (!contactInfo) {
        return null;
    }

    return (
        <div className={cn(style.tabingBoxTitle)}>
            <Grid container spacing={2} flex='1'>
                <Grid xs={12} sm={8} className={cn(style.vanderNameAvatar)}>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                        className={cn(style.listingAvatarLg)}
                    >
                        <ListItem alignItems='flex-start'>
                            <ListItemAvatar className={cn(style.avatarIcon)}>
                                <Avatar alt='User Icon' src={User} />
                            </ListItemAvatar>
                            <ListItemText className={cn(style.avatarContent)}>
                                <Typography variant='h4'>{`${contactInfo?.firstName} ${contactInfo?.lastName}`}</Typography>
                                <Typography variant='h6'>Main Contact</Typography>
                            </ListItemText>
                        </ListItem>
                        {contactInfo?.contactSpouse && (
                            <ListItem alignItems='flex-start'>
                                <ListItemAvatar className={cn(style.avatarIcon)}>
                                    <Avatar alt='User Icon' src={UserPlus} />
                                </ListItemAvatar>
                                <ListItemText className={cn(style.avatarContent)}>
                                    <Typography variant='h4'>{`${contactInfo.contactSpouse.firstName} ${contactInfo.contactSpouse.lastName}`}</Typography>
                                    <Typography variant='h6'>
                                        Spouse
                                        <span
                                            className={cn(
                                                contactInfo.contactSpouse.nonPurchasingSpouse
                                                    ? style.successBox
                                                    : style.errorBox,
                                            )}
                                        >
                                            {contactInfo.contactSpouse.nonPurchasingSpouse ? (
                                                <img alt='Check Icon' src={CheckIcon} />
                                            ) : (
                                                <img alt='Close' src={CloseWhite} />
                                            )}
                                            Co-Borrower
                                        </span>
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        )}
                    </List>
                    <div style={{ display: 'flex' }}>
                        {!!contactInfo?.additionalBorrowerList?.length &&
                            contactInfo?.additionalBorrowerList.map((v, i) => (
                                <List
                                    key={i}
                                    sx={{ bgcolor: 'background.paper' }}
                                    className={cn(style.listingAvatarSmall)}
                                >
                                    <ListItem alignItems='flex-start'>
                                        <ListItemAvatar className={cn(style.avatarIcon)}>
                                            <Avatar alt='Users Icon' src={Users} />
                                        </ListItemAvatar>
                                        <ListItemText className={cn(style.avatarContent)}>
                                            <Typography variant='h4'>
                                                {v.firstName} {v.lastName}
                                            </Typography>
                                            <Typography variant='h6'>
                                                Add. Borrower{' '}
                                                {v?.borrowingType && <span
                                                    className={cn(
                                                        v?.borrowingType === 'Occupant' ? style.successBox : style.errorBox,
                                                    )}
                                                >
                                                    {v.borrowingType === 'Occupant' ? (
                                                        <img alt='Check Icon' src={CheckIcon} />
                                                    ) : (
                                                        <img alt='Close' src={CloseWhite} />
                                                    )}
                                                    {v.borrowingType}
                                                </span>}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            ))}
                    </div>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper', marginTop: '10px' }}
                        className={cn(style.contectEmail)}
                    >
                        <ListItem alignItems='flex-start'>
                            <ListItemAvatar className={cn(style.avatarIcon)}>
                                <Avatar alt='Mail Icon' src={Mail} />
                            </ListItemAvatar>
                            <ListItemText>
                                <strong>Main Email:</strong> {contactInfo?.emailAddress}
                            </ListItemText>
                        </ListItem>
                        <ListItem alignItems='flex-start'>
                            <ListItemAvatar className={cn(style.avatarIcon)}>
                                <Avatar alt='Mobile' src={DeviceMobile} />
                            </ListItemAvatar>
                            <ListItemText>
                                <strong>Main Phone:</strong> {contactInfo?.phoneNumber}
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Grid>
                <LoadingButton
                    loading={statusLoading}
                    aria-haspopup='true'
                    onClick={handleMenuOpen}
                    sx={{
                        background: statusOptions?.find((s) => s.value === status?.value)?.color,
                        color: 'white',
                        ':hover': {
                            background: statusOptions?.find((s) => s.value === status?.value)?.color,
                        },
                    }}
                >
                    <Typography textTransform='capitalize'>{status?.label}</Typography>
                </LoadingButton>
                <Menu
                    id='menu-list'
                    elevation={4}
                    open={Boolean(anchorEl)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                >
                    {statusOptions?.map((item, index) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleStatusChange(contactInfo.id, item)}
                            sx={{ m: '5px', borderRadius: '7px', gap: '10px' }}
                        >
                            <Dot color={item.color} size={6} />
                            {item.label}
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
        </div>
    )
}

export default TabHeader
