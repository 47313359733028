import React from 'react'
import { useToggle } from '@mantine/hooks'
import { useController } from 'react-hook-form'
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { VisibilityOff, Visibility } from '@mui/icons-material'

type TProps = {
  name: string
  placeholder?: string
} & TextFieldProps

const PasswordInputField: React.FC<TProps> = (props) => {
  const [showPass, toggleShowPass] = useToggle()
  const { name, placeholder = '', ...rest } = props
  const { field, fieldState } = useController({ name, defaultValue: '' })
  const { error } = fieldState

  const endAdornment = field.value?.length > 0 && (
    <InputAdornment position='end'>
      <IconButton onClick={() => toggleShowPass()}>
        {showPass ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )

  const config = {
    ...field,
    ...rest,
    placeholder,
    error: !!error,
    type: showPass ? 'text' : 'password',
    helperText: error?.message,
    endAdornment,
  }
  return <TextField fullWidth {...config} />
}

export default PasswordInputField
