import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, SetStateAction, useEffect } from 'react'

import { Button, IconButton, Typography, Grid, Drawer } from '@mui/material'
import { Close } from '@mui/icons-material'

import apis from 'apis'
import cn from 'classnames'
import { AutocompleteField, DatePickerField, TextInputField, PhoneNumberField } from 'components'
import { CITIZENSHIP } from 'helpers/constants'
import { useAPI, useNotification } from 'hooks'
import { FormProvider, useForm } from 'react-hook-form'
import { themeColor } from 'theme'
import { TContactInitialValues } from 'types/contact'
import { ContactInitialValues, contactValidationSchema } from 'utils'
import style from '../App/styles/AppMenu.module.scss'
import { useNavigate } from 'react-router-dom'
import useUserStore from 'store/userStore'
import { UserPlus } from 'assets/svg';

type TProps = {
  toggleContactForm: (value?: SetStateAction<boolean> | undefined) => void
  showContactForm: boolean
}

const ContactForm = ({ toggleContactForm, showContactForm }: TProps) => {
  const { data: statusList } = useAPI(apis.contact.statusList)

  const navigate = useNavigate()
  const notification = useNotification()
  const { userDetails } = useUserStore()

  const statusOptions = statusList?.data?.map((i) => {
    return { value: i.id, label: i.status }
  })

  const styles = {
    fieldTitle: {
      color: themeColor.light.secondaryTextColor,
      fontSize: '14px',
      fontWeight: '500',

      marginBottom: '0px',
      display: 'block',
      textTransform: 'capitalize',
    },
    mainTitle: {
      color: '#5D596C',
      fontSize: '24px',
      fontWeight: '600',
    },
  }

  const formProps = useForm({
    mode: 'all',
    resolver: yupResolver(contactValidationSchema),
    defaultValues: ContactInitialValues,
  })

  const {
    reset,
    formState: { isValid, isDirty },
    handleSubmit,
    setValue,
    getFieldState,
  } = formProps

  const submitHandler = async (values: TContactInitialValues) => {
    const payload = {
      owner: userDetails?.userId,
      firstName: values.firstName,
      lastName: values.lastName,
      dateOfBirth: values.dob,
      citizenship: values.citizenship?.value && values.citizenship.value,
      emailAddress: values.email,
      phoneNumber: values.phone,
      notes: values.note,
      status: values.status?.value && values.status.value,
      hasSpouse: true,
      additionalBorrowers: true,
    }

    try {
      const res = await apis.contact.addNewContact(payload)
      notification.showSuccess('New Contact Created')
      toggleContactForm()
      reset()
      navigate(`/contacts/${res.data.data.id}`)
    } catch (err: any) {
      notification.showError(err.response?.data?.message || 'Something went wrong!')
    }
  }

  // const FieldTitle = ({ children }: { children: ReactNode }) => {
  //   return (
  //     <Typography variant='body2' className={cn(style.Title1)} sx={{ ...styles.fieldTitle }}>
  //       {children}
  //     </Typography>
  //   )
  // }

  useEffect(() => {
    const status = getFieldState('status')

    if (statusOptions?.length && !status?.isTouched)
      setValue('status', statusOptions[0], { shouldDirty: true, shouldTouch: true })
  }, [statusOptions])

  return (
    <Fragment key={'right'}>
      <Drawer
        anchor='right'
        PaperProps={{ style: { borderRadius: '20px 0 0 20px' } }}
        open={showContactForm}
        sx={{ borderRadius: '34px' }}
      >
        <div className={cn(style.dialogInner)}>
          <IconButton
            type='button'
            size='medium'
            sx={{ p: '0px', position: 'absolute', right: '15px' }}
            onClick={() => toggleContactForm()}
          >
            <Close color='action' style={{ color: 'gray' }} fontSize='small' />
          </IconButton>

          <div className={cn(style.createForm)}>
            <FormProvider {...formProps}>
              <div className={cn(style.form)}>
                <Grid container spacing={2}>
                  <Grid
                    spacing={10}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: 10, pl: '45%' }}>
                    <Grid style={{ height: '75px', width: '90px', backgroundColor: '#fff', borderRadius: '10px' }}>
                      <img src={UserPlus} style={{ margin: '10px', color: '#27ace3', width: '80%', height: '80%' }} />
                    </Grid>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography
                      sx={{ ...styles.mainTitle }}
                      variant='h6'
                      className={cn(style.Title1)}
                    >
                      Create A New Contact
                    </Typography>
                    <Typography className={cn(style.Title1)} sx={{ mb: '15px' }}>
                      Enter the information below to create a new contact in your database.
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <AutocompleteField
                      name='status'
                      options={statusOptions || []}
                      textFieldProps={{ size: 'small', label: 'Status' }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <TextInputField label='First Name' name='firstName' size='small' />
                  </Grid>
                  <Grid item sm={12}>
                    <TextInputField label='Last Name' name='lastName' size='small' />
                  </Grid>
                  <Grid item sm={12}>
                    <PhoneNumberField label='Phone' name='phone' size='small' />
                  </Grid>
                  <Grid item sm={12}>
                    <TextInputField label='Email' name='email' size='small' />
                  </Grid>
                  <Grid item sm={12}>
                    <DatePickerField label='Date of Birth' name='dob' datePickerProps={{ disableOpenPicker: true }} />
                  </Grid>
                  <Grid item sm={12}>
                    <AutocompleteField
                      name='citizenship'
                      options={CITIZENSHIP}
                      textFieldProps={{ size: 'small', label: 'Citizenship Type' }}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <TextInputField multiline minRows={2} name='note' label='Note' />
                  </Grid>
                  <Grid item sm={12}>
                    <Button
                      disabled={!isDirty || !isValid}
                      variant='contained'
                      size='medium'
                      fullWidth
                      onClick={handleSubmit(submitHandler)}
                    >
                      Create Contact
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </FormProvider>
          </div>
        </div>
      </Drawer>
    </Fragment>
  )
}

export default ContactForm
