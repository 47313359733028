import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { NotFound, ProtectedRoute } from './components'
import Dashboard from './pages/ProtectedRoutes/Dashboard'
import SignIn from './pages/SignIn/SignIn'
import SignUp from './pages/SignUp/SignUp'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import ContactInfo from 'pages/ProtectedRoutes/ContactInfo'
import Goals from 'pages/ProtectedRoutes/Goals'
import Resource from 'pages/ProtectedRoutes/Resource'
import Profile from 'pages/ProtectedRoutes/Profile'
import Support from 'pages/ProtectedRoutes/Support'
import PageSetting from 'pages/ProtectedRoutes/PageSetting'
import Contacts from 'pages/ProtectedRoutes/Contacts'
import MyTeam from 'pages/ProtectedRoutes/MyTeam'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<ProtectedRoute />}>
          <Route index element={<Dashboard />} />
          <Route path='/test' element={<Dashboard />} />
          <Route path='/resources' element={<Resource />} />
          <Route path='/goals' element={<Goals />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/support' element={<Support />} />
          <Route path='/page-settings' element={<PageSetting />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/contacts/:id' element={<ContactInfo />} />
          <Route path='/my-team' element={<MyTeam />} />
        </Route>

        <Route path='/sign-in' element={<SignIn />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
