import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Button, LinearProgress } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInputField, AutocompleteField } from 'components'
import { TGoal, TGoalInitialValues } from 'types/contact'
import { contactGoalValidator, GoalInitialValues, purposeOptions, primaryFocusOptions } from 'utils'
import apis from 'apis'
import { useNotification, useApiMethod } from 'hooks'

const EditGoal = () => {
    const { id: contactId = 0 } = useParams()
    const [goal, setGoal] = useState<TGoal>()
    const notification = useNotification()
    const { callApi, isLoading } = useApiMethod()

    const formProps = useForm({
        mode: 'all',
        resolver: yupResolver(contactGoalValidator),
        defaultValues: GoalInitialValues,
    })

    const {
        handleSubmit,
        formState: { isValid, isDirty },
        reset
    } = formProps

    const getGoals = async () => {
        try {
            const res = await callApi(() => apis.contact.getGoals(contactId || 0))
            if (res.data) {
                setGoal(res.data);
                reset({
                    ...res.data,
                    purpose: purposeOptions.find(({ value }: { value: string }) => value === res.data.purpose),
                    primaryFocus: primaryFocusOptions.find(({ value }: { value: string }) => value === res.data.primaryFocus)
                })
            }
        } catch {
            // err
        }
    }

    useEffect(() => {
        getGoals();
    }, [contactId]);

    const onSaveChanges = async (values: TGoalInitialValues) => {
        const payload = {
            ...values,
            id: goal?.id || 0,
            purpose: values.purpose?.value || '',
            primaryFocus: values.primaryFocus?.value || '',
            contactId
        }
        try {
            const action = goal?.id ? apis.contact.updateGoal : apis.contact.addGoal;
            const respo = await action(payload)
            notification.showSuccess(respo.data.message)
        } catch {
            // err
        }
    }

    return (
        <>
            {isLoading && <LinearProgress />}
            <FormProvider {...formProps}>
                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <AutocompleteField
                            textFieldProps={{ label: 'Purpose', size: 'small' }}
                            name='purpose'
                            options={purposeOptions}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <AutocompleteField
                            textFieldProps={{ label: 'Primary Focus', size: 'small' }}
                            name='primaryFocus'
                            options={primaryFocusOptions}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextInputField
                            label='Loan Retention Period'
                            name='loanRetentionPeriod'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextInputField
                            label='Price Range/OEV'
                            name='priceRange'
                            size='small'
                            InputProps={{ startAdornment: '$' }}
                            type='number'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextInputField
                            label='Payment Range'
                            name='paymentRange'
                            size='small'
                            InputProps={{ startAdornment: '$' }}
                            type='number'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextInputField
                            label='Down/Cash Out Range'
                            name='cashOutRange'
                            size='small'
                            InputProps={{ startAdornment: '$' }}
                            type='number'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextInputField
                            label='Urgency - How soon do you need an approval?'
                            name='urgency'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextInputField
                            label='Home Types'
                            name='homeType'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextInputField
                            label='Loan Types'
                            name='loanType'
                            size='small'
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInputField
                            size='small'
                            multiline
                            rows={6}
                            label='What’s the customers biggest pain point?'
                            name='painPoint'
                        />
                    </Grid>
                    <Grid item xs={12} className={'actionBtn'} sx={{ marginTop: '20px' }}>
                        <Button
                            disabled={!isValid || !isDirty}
                            variant='contained'
                            size='medium'
                            className={'saveBtn'}
                            onClick={handleSubmit(onSaveChanges)}
                        >
                            Save Changes
                        </Button>
                        <Button variant='outlined' size='medium' className='cancelBtn'>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </FormProvider>
        </>
    )
}

export default EditGoal
