import app from './routes/app'
import auth from './routes/auth'
import common from './routes/common'
import contact from './routes/contact'
import resource from './routes/resource'

const apis = {
  app,
  auth,
  common,
  contact,
  resource
}

export default apis
