import { Typography } from '@mui/material'
import { TLoanInformation } from 'types/contact'
import Milestones from './Milestones'
import LoanProgressContent from './LoanProgressContent'

type TProps = {
    loanData: TLoanInformation
    callback: (x: TLoanInformation, y?: string) => void
    milestonesMap: any
}

const LoanProgress = ({ loanData, callback, milestonesMap }: TProps) => {
    return (
        <>
            <Typography variant='h6' sx={{ paddingY: '25px', fontWeight: '600' }}>
                Loan Progress
            </Typography>
            <Milestones data={loanData} milestonesMap={milestonesMap} />
            <LoanProgressContent loanData={loanData} callback={callback} />
        </>
    )
}

export default LoanProgress
