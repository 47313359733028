import React, { Fragment, SetStateAction } from 'react';

import cn from 'classnames'
import { IconButton, Grid, Drawer as MuiDrawer, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { themeColor } from 'theme'
import { UserPlus } from 'assets/svg';
import style from 'components/App/styles/AppMenu.module.scss';

type TProps = {
    onToggle: (value?: SetStateAction<boolean> | undefined) => void
    isShow: boolean
    children: React.ReactNode
    title: string
    subTitle?: string
}

const Drawer = ({ onToggle, isShow, ...rest }: TProps) => {

    const styles = {
        fieldTitle: {
            color: themeColor.light.secondaryTextColor,
            fontSize: '14px',
            fontWeight: '500',

            marginBottom: '0px',
            display: 'block',
            textTransform: 'capitalize',
        },
        mainTitle: {
            color: '#5D596C',
            fontSize: '24px',
            fontWeight: '600',
        },
    }

    return (
        <Fragment key={'right'}>
            <MuiDrawer
                anchor='right'
                PaperProps={{ style: { borderRadius: '20px 0 0 20px' } }}
                open={isShow}
                sx={{ borderRadius: '34px' }}
            >
                <div className={cn(style.dialogInner)}>
                    <IconButton
                        type='button'
                        size='medium'
                        sx={{ p: '0px', position: 'absolute', right: '15px' }}
                        onClick={() => onToggle()}
                    >
                        <CloseIcon color='action' style={{ color: 'gray' }} fontSize='small' />
                    </IconButton>

                    <div>
                        <Grid container spacing={2}>
                            <Grid
                                spacing={10}
                                alignItems="center"
                                justifyContent="center"
                                sx={{ pt: 10, pl: '45%' }}>
                                <Grid style={{ height: '75px', width: '90px', backgroundColor: '#fff', borderRadius: '10px' }}>
                                    <img src={UserPlus} style={{ margin: '10px', color: '#27ace3', width: '80%', height: '80%' }} />
                                </Grid>
                            </Grid>
                            <Grid item sm={12} sx={{ mb: '30px' }}>
                                <Typography
                                    sx={{ ...styles.mainTitle }}
                                    variant='h6'
                                >
                                    {rest.title}
                                </Typography>
                                {rest.subTitle && <Typography >
                                    {rest.subTitle}
                                </Typography>}
                            </Grid>
                        </Grid>
                        {rest.children}
                    </div>
                </div>
            </MuiDrawer>
        </Fragment>
    )
}

export default Drawer;