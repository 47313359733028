import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import useUserStore from 'store/userStore'
import Layout from './Layout'

const ProtectedRoute = () => {
  const navigate = useNavigate()

  const { isLoggedIn } = useUserStore()

  useEffect(() => {
    if (localStorage.getItem('token') === null || !isLoggedIn) {
      navigate('/sign-in')
      // navigate('/sign-up')
    }
  }, [])

  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default ProtectedRoute
