import dayjs from 'dayjs'
import { TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const DatePickerInput = ({ value, onChange, ...rest }: any) => {
    const config = {
        ...rest,
        error: value ? !dayjs(value, 'MM/DD/YYYY', true).isValid() : false,
        value: value || null,
        inputFormat: 'MM/DD/YYYY',
        onChange
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                {...config}
                renderInput={(params) => <TextField fullWidth size='small' {...params} {...rest} />}
            />
        </LocalizationProvider>
    )
}

export default DatePickerInput;