import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { Box, Chip, LinearProgress } from '@mui/material';
import { AddTask, EditTask, TaskAction, TaskGridType, IsCompleteCell } from './TaskUI';
import { DataGrid, TDataGridHeadCell } from 'components';
import { priorityTypes, getPriorityTypeColor, dateMDY } from 'utils';
import apis from 'apis'
import { TTask } from 'types/contact'
import { useApiMethod } from 'hooks'
import Note from './Note'

type TRowProps = {
  priority: 'urgent' | 'high' | 'normal'
}

const Task = () => {
  const { id: contactId = '' } = useParams()
  const [gridType, setGridType] = useState('to-do')
  const [showEditTask, setShowEditTask] = useState(false)
  const [record, setRecord] = useState<TTask>()
  const [allData, setAllData] = useState<TTask[]>([]);
  const [data, setData] = useState<TTask[]>([]);
  const [todayDueTasks, setTodayDueTasks] = useState<TTask[]>([]);
  const { callApi: getTaskData, isLoading: cLoading, } = useApiMethod()
  const isLoading = cLoading

  const filterGrid = (type: string, records: TTask[] = []) => {
    if (type === 'to-do') {
      const toDoTask = records.filter(item => (!item.isComplete)) || [];
      setData(toDoTask);
      setTodayDueTasks(toDoTask.filter(item => (item.dueDate && (dateMDY(item.dueDate) === dateMDY(new Date())))) || [])
    } else {
      setData(records.filter(item => (item.isComplete)) || []);
    }
  }

  const getRecords = async () => {
    try {
      if (contactId) {
        const resp = await getTaskData(() => apis.contact.getTasks(contactId || 0))
        setAllData(resp?.data || []);
        filterGrid(gridType, resp?.data || []);
      }
    } catch {
      // err
    }
  }

  useEffect(() => {
    getRecords();
  }, [contactId])

  const toggleEditTask = (row?: TTask) => {
    setRecord(row)
    setShowEditTask(!showEditTask)
  }

  const toggleGridType = (type: string) => {
    setGridType(type);
    filterGrid(type, allData);
  }

  const cells: TDataGridHeadCell[] = [
    {
      label: 'Priority',
      name: 'priority',
      render: ({ row }: { row: TRowProps }) => (
        <Chip label={priorityTypes[row.priority]} color={getPriorityTypeColor(row.priority)} sx={{ width: '47px', height: '25px' }} />
      ),
      props: { align: 'center' }
    },
    {
      label: 'Task', name: 'taskName',
      props: { align: 'center', sx: { width: '400px' } }
    },
    {
      label: 'Due Date', name: 'dueDate',
      render: ({ row }: { row: TTask }) => dateMDY(row.dueDate),
      props: { align: 'center' }
    },
    {
      label: 'Assignee',
      name: 'assignee',
      props: { align: 'center' },
      render: ({ row }: { row: TTask }) => `${row.assigneeFirstName} ${row.assigneeLastName}`
    },
    {
      label: 'Complete',
      name: 'isComplete',
      render: ({ row }) => (
        <IsCompleteCell key={row.id} row={row} callback={getRecords} />
      ),
      props: { align: 'center' }
    },
    {
      label: 'ACTIONS',
      name: 'actions',
      props: { align: 'center' },
      render: ({ row }) => (
        <TaskAction row={row} callback={getRecords} toggleEdit={toggleEditTask} />
      )
    },
  ];

  return (
    <>
      <Box>
        <AddTask contactId={contactId} callback={getRecords} />
      </Box>
      <Box sx={{ marginTop: '1rem' }}>
        {isLoading && <LinearProgress />}
        <DataGrid
          cells={cells}
          data={data}
          toolbarProps={{
            title: gridType === 'to-do' ? `You have ${todayDueTasks.length} Task Due Today` : 'Completed Tasks',
            rightIcon: <TaskGridType type={gridType} onToggle={toggleGridType} />
          }}
        />
        {(showEditTask && record) && <EditTask
          isShow={showEditTask}
          onToggle={toggleEditTask}
          record={record}
          contactId={contactId}
          callback={getRecords}
        />}
      </Box>
      <Note />
    </>
  )
}

export default Task
