import { Typography, Box } from '@mui/material';

const TableToolbar = ({ title, rightIcon, subTitle }: { title?: any, rightIcon?: any, subTitle?: any }) => {
    if (!title && !rightIcon) return null;
    return (
        <Box sx={{ borderBottom: '1px solid #DBDADE' }}>
            <Box
                sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    display: 'flex',
                    minHeight: '64px',
                    alignItems: 'center'
                }}
            >
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                    {title}
                </Typography>
                {rightIcon}
            </Box>
            <Box sx={{ pl: 2, pr: 2 }} >
                {subTitle}
            </Box>
        </Box>
    );
}

export default TableToolbar;