import { useState } from 'react';
import { Checkbox } from '@mui/material';
import { TTask } from 'types/contact'
import apis from 'apis'
import { useNotification } from 'hooks'

type TProps = {
    row: TTask
    callback: () => void
}

const IsCompleteCell = ({ row, callback }: TProps) => {
    const [ckecked, setChecked] = useState(Boolean(row.isComplete));
    const notification = useNotification()

    const onChange = async (event: any) => {
        setChecked(event.target.checked);
        try {
            const values = { ...row, isComplete: event.target.checked }
            await apis.contact.updateTask(values)
            callback();
        } catch {
            setChecked(!event.target.checked)
            notification.showError('Error in saving values!')
        }
    }

    return (
        <Checkbox
            name={`isComplete_${row.id}`}
            sx={{ padding: 'unset' }}
            checked={ckecked}
            onChange={onChange}
        />
    );
}

export default IsCompleteCell;