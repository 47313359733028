import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        ref={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

type TProps = {
  name: string
  placeholder?: string
} & TextFieldProps

const PhoneNumberField: React.FC<TProps> = (props) => {
  const { name, placeholder = '', type, ...rest } = props
  const { field, fieldState } = useController({ name, defaultValue: '' })
  const { error } = fieldState

  const config = {
    ...field,
    ...rest,
    placeholder,
    type,
    error: !!error,
    helperText: error?.message,
    InputProps: {
      inputComponent: TextMaskCustom as any,
    }
  }
  return <TextField fullWidth {...config} />
}

export default PhoneNumberField
