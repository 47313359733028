import { TAutocompleteOptions } from 'types/global.types'
import { FORM_VALIDATION_STRINGS as FVS } from 'helpers/constants'
import * as Yup from 'yup'

const nmlsValidation = () => Yup.string().nullable().required(FVS.REQUIRED())
// .matches(/^[0-9]*$/, NUMBER)
const nameValidation = () => Yup.string().nullable().required(FVS.REQUIRED())
const emailValidation = () =>
  Yup.string().nullable().email(FVS.NOT_VALID('Email')).required(FVS.REQUIRED())
const autocompleteValidation = () =>
  Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).nullable()

export const loginValidationSchema = Yup.object().shape({
  email: emailValidation(),
  password: Yup.string().nullable().required(FVS.REQUIRED()),
})

export const signUpValidationSchema = Yup.object().shape({
  companyName: nameValidation(),
  companyNmlsId: nmlsValidation(),
  name: nameValidation(),
  title: autocompleteValidation().required(FVS.REQUIRED()),
  nmlsId: Yup.string().when('title', {
    is: (titleVal: TAutocompleteOptions) => {
      return titleVal && (titleVal?.value == 1 || titleVal?.value == 2)
    },
    then: nmlsValidation(),
    otherwise: Yup.string().notRequired(),
  }),
  email: emailValidation(),
  phone: Yup.string().required(FVS.REQUIRED()),
  // .matches(PHONE_REGX, NOT_VALID('Phone number'))
  // .min(10, MIN_LEN('Phone number', '10'))
  // .max(10, MAX_LEN('Phone number', '10')),
  password: Yup.string().nullable().required().matches(FVS.PASSWORD_REGX, FVS.PASSWORD),
  confirmPassword: Yup.string()
    .nullable()
    .required(FVS.REQUIRED())
    .oneOf([Yup.ref('password')], 'Password does not match'),
})
