import Router from './Router'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { getTheme } from 'theme'
import 'material-react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'material-react-toastify'

const App = () => {
  const appTheme = 'light'
  const theme = getTheme(appTheme)
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
      <ToastContainer hideProgressBar closeButton position='top-right' />
    </>
  )
}

export default App
