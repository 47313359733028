import { Box, Divider, Tab, Tabs } from '@mui/material';
import {
  CaseStatusIcon,
  ListDetailsIcon,
} from 'assets/svg'
import cn from 'classnames'
import style from '../styles/contacts.module.scss'
import TabPanel from 'components/General/TabPanel'
import { useState } from 'react'
import TabHeader from '../TabHeader'
import CaseStatus from './CaseStatus'
import Task from './Task'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const TaskTabs = () => {
  const [currentTab, setCurrentTab] = useState(0)

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  const tabs = [
    {
      label: 'Tasks & Notes',
      icon: <img src={ListDetailsIcon} alt='Tasks' className={cn(style.imgIcon)} />,
      component: <Task />,
    },
    {
      label: 'Case Status',
      icon: <img src={CaseStatusIcon} alt='Case Status' className={cn(style.imgIcon)} />,
      component: <CaseStatus />,
    },
  ]

  return (
    <>
      <TabHeader />
      <Divider />
      <div className={cn(style.tabingBoxBody)}>
        <Box sx={{ width: '100%' }}>
          <Box className={cn(style.tabingBoxTabTwo)}>
            <Tabs value={currentTab} onChange={handleChange} aria-label='basic tabs example' classes={{ flexContainer: style.tabs }}
            >
              {tabs.map((tab, index) => (
                <Tab key={index} icon={tab.icon} label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>
          <Box className={cn(style.tabingBoxPanel2)}>
            {tabs.map((tab, index) => (
              <TabPanel value={currentTab} index={index} key={index}>
                {tab.component}
              </TabPanel>
            ))}
          </Box>
        </Box>
      </div>
    </>
  )
}

export default TaskTabs
