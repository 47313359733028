import { FORM_VALIDATION_STRINGS as FVS } from 'helpers/constants'
import * as Yup from 'yup'

const autocompleteValidation = () =>
  Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).nullable()

export const contactValidationSchema = Yup.object().shape({
  status: autocompleteValidation(),
  firstName: Yup.string().nullable().required(FVS.REQUIRED()),
  lastName: Yup.string().nullable().required(FVS.REQUIRED()),
  email: Yup.string().nullable().email(FVS.NOT_VALID('Email')),
  phone: Yup.string().nullable(),
  // .matches(PHONE_REGX, NOT_VALID('Phone number'))
  // .min(10, MIN_LEN('Phone number', '10'))
  // .max(10, MAX_LEN('Phone number', '10')),
  dob: Yup.date().nullable().max(new Date(), FVS.NOT_VALID('Date of Birth')),
  citizenship: autocompleteValidation(),
})

const contactValidation = {
  firstName: Yup.string().nullable().required(FVS.REQUIRED()),
  lastName: Yup.string().nullable().required(FVS.REQUIRED()),
  emailAddress: Yup.string().nullable().email(FVS.NOT_VALID('Email')).required(FVS.REQUIRED()),
  phoneNumber: Yup.string().nullable(),
  dateOfBirth: Yup.date().nullable().max(new Date(), FVS.NOT_VALID('Date of Birth')),
  citizenship: autocompleteValidation(),
}
export const contactUpdateValidator = Yup.object({ ...contactValidation })

export const contactUpdateWithSpouseValidator = Yup.object({
  ...contactValidation,
  spouse: Yup.object({
    ...contactValidation,
    emailAddress: Yup.string().nullable().email(FVS.NOT_VALID('Email')),
    borrowingType: autocompleteValidation(),
    nps: autocompleteValidation(),
  }),
})

const addressValidation = {
  addressHistoryType: autocompleteValidation(),
  monthlyAmount: Yup.number().nullable().typeError('Please enter valid amount').transform((_, val) => (val !== '' ? Number(val) : null)),
  moveInDate: Yup.date().nullable(),
  moveOutDate: Yup.date().nullable(),
  street: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: autocompleteValidation(),
  zip: Yup.string().nullable(),
}

export const addressHistoryValidator = Yup.object({
  ...addressValidation,
  prior: Yup.object({ ...addressValidation }),
})

export const additionalBorrowerValidator = Yup.object({
  borrower: Yup.array().of(
    Yup.object({
      firstName: Yup.string().nullable(),
      lastName: Yup.string().nullable(),
      relationshipStatus: autocompleteValidation(),
      dateOfBirth: Yup.date().nullable(),
      citizenship: autocompleteValidation(),
      borrowingType: autocompleteValidation(),
      emailAddress: Yup.string().nullable().email(FVS.NOT_VALID('Email')).nullable(),
      phoneNumber: Yup.string().nullable(),
    }),
  ),
})

export const contactTaskValidator = Yup.object({
  contractAcceptance: Yup.date().nullable(),
  // escrowDays: Yup.number().transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value).nullable().positive(),
  escrowDays: Yup.number().transform((_, val) => (val !== '' ? Number(val) : null)).nullable().positive(),
  closingDate: Yup.date().nullable(),
  targetDate: Yup.date().nullable(),
})

export const addContactTaskValidator = Yup.object({
  contactId: autocompleteValidation().required(FVS.REQUIRED()),
  taskName: Yup.string().required(FVS.REQUIRED()).min(5, FVS.MIN_LENGTH('Name', '5')),
  assignee: autocompleteValidation().required(FVS.REQUIRED()),
  dueDate: Yup.date().nullable(),
  priority: autocompleteValidation().required(FVS.REQUIRED()),
})

export const addTaskValidator = Yup.object({
  taskName: Yup.string().required(FVS.REQUIRED()).min(5, FVS.MIN_LENGTH('Name', '5')),
  assignee: autocompleteValidation().required(FVS.REQUIRED()),
  dueDate: Yup.date().nullable(),
  priority: autocompleteValidation().required(FVS.REQUIRED()),
})

export const contactGoalValidator = Yup.object({
  purpose: autocompleteValidation(),
  primaryFocus: autocompleteValidation(),
  loanRetentionPeriod: Yup.string().nullable(),
  priceRange: Yup.string().nullable(),
  paymentRange: Yup.string().nullable(),
  cashOutRange: Yup.string().nullable(),
  urgency: Yup.string().nullable(),
  homeType: Yup.string().nullable(),
  loanType: Yup.string().nullable(),
  painPoint: Yup.string().nullable(),
})

export const contactNoteValidator = Yup.object({
  note: Yup.string().required(FVS.REQUIRED()).max(1000, FVS.MAX_LENGTH('Note', '1000')),
  type: Yup.string().required(FVS.REQUIRED())
})

export const contactLoanInfoValidator = Yup.object({
  loanNumber: Yup.string().nullable(),
  escrowNumber: Yup.string().nullable(),
  subjectAddress: Yup.string().nullable(),
  city: Yup.string().nullable(),
  country: Yup.string().nullable(),
  state: Yup.string().nullable(),
  zip: Yup.string().nullable(),
  fundingSource: Yup.string().nullable(),
  purpose: autocompleteValidation(),
  loantype: Yup.string().nullable(),
  product: Yup.string().nullable(),
  price: Yup.string().nullable(),
  totalFinanced: Yup.string().nullable(),
  piti: Yup.string().nullable(),
  rate: Yup.number().nullable().typeError('Please enter valid number').transform((_, val) => (val !== '' ? Number(val) : null)).max(100, 'Enter a rate % of at most 100'),
  cashToClose: Yup.string().nullable(),
})

