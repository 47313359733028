import { TSignUpInitialValues } from 'types/auth'

export const loginInitialValues = {
  email: '',
  password: '',
}

export const signUpInitialValues: TSignUpInitialValues = {
  companyName: '',
  companyNmlsId: '',
  title: null,
  name: '',
  nmlsId: '',
  email: '',
  phone: '',
  password: '',
  confirmPassword: '',
}
