import { Grid, Table, TableBody, TableCell, TableRow, Checkbox, FormControlLabel, Box, InputLabel, TextField } from '@mui/material'
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import { themeColor } from 'theme';
import { TLoanInformation, TLoanCheckList, TLoanChecklistTask } from 'types/contact';
import { DatePickerInput } from 'components';
import { dateMDY } from 'utils';
import apis from 'apis';
import { useNotification } from 'hooks';

const StyleTable = styled(Table)(() => ({
    whiteSpace: 'pre',
    border: '1px solid #DBDADE',
    tableLayout: 'auto',
    '& tbody': {
        '& tr': {
            '& td': {
                fontSize: 'inherit',
                padding: '12px 8px',
                color: themeColor.light.secondaryTextColor
            }
        }
    }
}));

type TProps = {
    loanData: TLoanInformation
    callback: (x: any, y?: string) => void
}

const LoanProgressContent = ({ loanData, callback }: TProps) => {
    const notification = useNotification()
    const { interestRateLockTracker, loanCheckLists, loanChecklistTasks } = loanData;

    const onChangeLCIsCompleteHandler = async (event: any, listId: number) => {
        const values = {
            isComplete: event.target.checked,
            completionDate: event.target.checked ? dayjs() : null
        }
        updateLoanChecklist(values, listId);

    }

    const updateLoanChecklist = async (values: any, listId: number) => {
        const item = loanCheckLists.find(({ id }: { id: number }) => id === listId);
        const payload = {
            ...item,
            ...values
        }
        const updated = loanCheckLists.map(item => (item.id === listId ? { ...payload } : { ...item }))
        callback({ ...loanData, loanCheckLists: updated }, 'loanCheckLists');
        if (values.completionDate && !dayjs(values.completionDate, 'MM/DD/YYYY', true).isValid()) {
            return false;
        }
        try {
            await apis.contact.updateLoanChecklist(payload)
        } catch {
            notification.showError('Error in saving values!')
        }
    }

    const updateTaskStatus = async (values: any, listId: number) => {
        const item = loanChecklistTasks.find(({ id }: { id: number }) => id === listId);
        const payload = {
            ...item,
            ...values
        }
        try {
            const updated = loanChecklistTasks.map(item => (item.id === listId ? { ...payload } : { ...item }))
            callback({ ...loanData, loanChecklistTasks: updated });
            await apis.contact.updateTaskStatus(payload)
        } catch {
            notification.showError('Error in saving values!')
        }
    }

    const updateInterestRateLockTracker = async (event: any, listId: number) => {
        const item = loanCheckLists.find(({ id }: { id: number }) => id === listId);
        const expirationDate = item?.completionDate ? dayjs(item.completionDate).add(+event.target.value, 'day').toDate() : null;
        const daysLocked = +event.target.value;
        const payload = {
            ...interestRateLockTracker,
            daysLocked,
            expirationDate
        }
        callback({ ...loanData, interestRateLockTracker: payload });
        try {
            await apis.contact.updateInterestRateLockTracker(payload)
        } catch {
            notification.showError('Error in saving values!')
        }
    }

    return (
        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            <Grid item xs={12}>
                <StyleTable border={1}>
                    <TableBody>
                        {loanCheckLists.map((item: TLoanCheckList) => {
                            return (
                                <TableRow key={item.id}>
                                    <TableCell sx={{ width: '250px' }}> {item.section} </TableCell>
                                    <TableCell sx={{ width: '220px' }}>
                                        <Box sx={{ display: 'flex' }} >
                                            <Checkbox
                                                checked={item.isComplete}
                                                onChange={(event: any) => onChangeLCIsCompleteHandler(event, item.id)}
                                            />
                                            <DatePickerInput
                                                name={`completionDate-${item.id}`}
                                                value={item.completionDate}
                                                onChange={(date: any) => updateLoanChecklist({ completionDate: date }, item.id)}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        sx={item.type === 'SubTasks' ? { paddingLeft: 'unset', paddingRight: 'unset' } : {}}>
                                        {item.type === 'SubTasks' && loanChecklistTasks.filter(({ loanChecklistLookupId }: TLoanChecklistTask) => loanChecklistLookupId === item.loanChecklistLookupId)?.map((taskItem, index) => (
                                            <Box key={`task-${taskItem.id}`} sx={{ display: 'flex' }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={taskItem.isComplete}
                                                            onChange={(e: any) => updateTaskStatus({ isComplete: e.target.checked }, taskItem.id)}
                                                            sx={{ padding: '0 9px' }}

                                                        />
                                                    }
                                                    label={taskItem.task}
                                                    sx={{ padding: '5px 20px', background: index % 2 === 0 ? 'unset' : '#FAF7F7', width: '100%', margin: 'unset' }}
                                                />
                                            </Box>
                                        ))}

                                        {item.type === 'Expiration' && <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                            <TextField
                                                name="exp"
                                                type="number"
                                                value={interestRateLockTracker.daysLocked || ''}
                                                size="small"
                                                inputProps={{ sx: { maxWidth: '30px' } }}
                                                onChange={(e: any) => updateInterestRateLockTracker(e, item.id)}
                                            />
                                            <InputLabel>Days Locked</InputLabel>
                                            <InputLabel><>Expires On: {dateMDY(interestRateLockTracker.expirationDate)}</></InputLabel>
                                        </Box>
                                        }
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </StyleTable>
            </Grid>
        </Grid >
    )
}

export default LoanProgressContent
