import { FORM_VALIDATION_STRINGS as FVS } from 'helpers/constants';
import * as Yup from 'yup';

const autocompleteValidation = () =>
    Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }).nullable().required(FVS.REQUIRED())

export const resourceValidationSchema = Yup.object().shape({
    resourceTypeLookupId: autocompleteValidation(),
    firstName: Yup.string().nullable().required(FVS.REQUIRED()),
    lastName: Yup.string().nullable().required(FVS.REQUIRED()),
    emailAddress: Yup.string().nullable().email(FVS.NOT_VALID('Email')).required(FVS.REQUIRED()),
    phoneNumber: Yup.string().nullable(),
    assistantFirstName: Yup.string().nullable(),
    assistantLastName: Yup.string().nullable(),
    assistantPhone: Yup.string().nullable(),
    assistantEmail: Yup.string().nullable().email(FVS.NOT_VALID('Assistant Email')),
})