import { useEffect, useState } from 'react'
import { Grid, Button, Typography } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { DatePickerField, TextInputField, AutocompleteField } from 'components'
import { addTaskValidator, priorityOptions, TaskInitialValues } from 'utils'
import { TTasInitialValues } from 'types/contact'
import apis from 'apis'
import { useNotification } from 'hooks'

type TProps = {
    contactId: number | string
    callback: () => void
}

const AddTask = ({ contactId, callback }: TProps) => {
    const [assigneeOptions, setAssigneeOptions] = useState<{ value: number, label: string }[]>([])
    const notification = useNotification()

    const formProps = useForm({
        mode: 'all',
        resolver: yupResolver(addTaskValidator),
        defaultValues: TaskInitialValues,
    })

    const {
        handleSubmit,
        setValue,
        formState: { isValid, isDirty },
    } = formProps

    const getAssigneeList = async () => {
        try {
            const res = await apis.resource.getAssignedResources(contactId);
            const options = res.data.data?.map(item => ({ value: item.id, label: `${item.firstName} ${item.lastName}` })) || []
            setAssigneeOptions(options)
            if (options.length) {
                setValue('assignee', options[0]);
            }
        } catch {
            // err
        }
    }

    useEffect(() => {
        getAssigneeList();
    }, [contactId])

    const onSaveChanges = async (values: TTasInitialValues) => {
        const payload = {
            ...values,
            contactId,
            assignee: values.assignee?.value || 0,
            priority: values.priority?.value || '',
        }
        try {
            const respo = await apis.contact.addTask(payload)
            notification.showSuccess(respo.data.message)
            formProps.reset({
                ...TaskInitialValues,
                assignee: assigneeOptions[0]
            });
            callback();
        } catch {
            notification.showError('Error in saving values!')
        }
    }

    return (
        <FormProvider {...formProps}>
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ fontWeight: '600' }}>
                        Create New Task
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextInputField
                        label='Task'
                        name='taskName'
                        placeholder='Create New Task'
                        size='small'
                    />
                </Grid>
                <Grid container item spacing={1} sm={6} md={6}>
                    <Grid item xs={12} sm={4}>
                        <AutocompleteField
                            textFieldProps={{ label: 'Assignee', size: 'small' }}
                            name='assignee'
                            options={assigneeOptions}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <DatePickerField
                            label='Due Date'
                            name='dueDate'
                            placeholder='Due Date'
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <AutocompleteField
                            textFieldProps={{ label: 'Priority', size: 'small' }}
                            name='priority'
                            options={priorityOptions}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Button
                            disabled={!isValid || !isDirty}
                            variant='contained'
                            size='medium'
                            className={'saveBtn'}
                            onClick={handleSubmit(onSaveChanges)}
                        >
                            Create Task
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    )
}

export default AddTask
