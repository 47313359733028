const StipulationTabs = () => {

  return (
    <>
      Stipulations
    </>
  )
}

export default StipulationTabs
