import { useState, useEffect } from 'react';
import { useToggle } from '@mantine/hooks';
import cn from 'classnames';
import { Box, Breadcrumbs, Typography, Link, Grid, Button, Alert, LinearProgress } from '@mui/material';
import { WorkspacesOutlined as WorkspacesOutlinedIcon, AddSharp as AddSharpIcon, PersonSearchOutlined as PersonSearchOutlinedIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import style from 'pages/styles/ProtectedRoute.module.scss'
import { TResource } from 'types/resource';
import { DataGrid, TDataGridHeadCell } from 'components';
import { useApiMethod } from 'hooks';
import apis from 'apis'
import { UserSearchIcon } from 'assets/svg'
import useUserStore from 'store/userStore'
import ResourceForm from './ResourceForm';
import ResourceAction from './ResourceAction';

const Img = styled('img')(() => ({
    cursor: 'pointer',
    height: '24px', width: '24px'
}));

const Resource = () => {
    const [showResourceForm, toggleResourceForm] = useToggle();
    const [resources, setResources] = useState<TResource[]>([]);
    const [resource, setResource] = useState<TResource | null>(null);
    const { callApi, isLoading } = useApiMethod();
    const { userDetails, resourceTypes, setResourceTypes } = useUserStore();

    const getRecords = async () => {
        if (userDetails?.userId) {
            try {
                const resp = await callApi(() => apis.resource.getResources(userDetails.userId));
                const data = resp.data?.filter((item: any) => (![7, 8].includes(item.resourceTypeLookupId))) || [];
                setResources(data);
            } catch {
                // err
            }
        }
    }

    useEffect(() => {
        getRecords();
    }, [userDetails])

    const getResourceType = async () => {
        try {
            if (!resourceTypes?.length) {
                const respo = await apis.common.getResourceType()
                if (respo.data?.data) {
                    setResourceTypes(respo.data.data);
                }
            }
        } catch {
            // error
        }
    }

    useEffect(() => {
        getResourceType()
    }, [])

    const toggleResource = (row?: TResource | null) => {
        setResource(row || null);
        toggleResourceForm();
    }

    const updateResources = (item: TResource, type = 'edit') => {
        if (type === 'delete') {
            setResources(resources.filter((row: TResource) => (row.id !== item.id)) || []);
        } else if (type === 'edit') {
            setResources(resources.map((row: TResource) => (row.id === item.id ? item : row)));
        } else {
            getRecords();
        }
    }

    const cells: TDataGridHeadCell[] = [
        {
            label: '',
            name: 'actionsIcon',
            props: { align: 'center', sx: { width: '64px' } },
            render: ({ row }) => <Img src={UserSearchIcon} alt='' onClick={() => toggleResource(row)} />
        },
        {
            label: 'Title',
            name: 'resourceTypeLookupId',
            render: ({ row }) => resourceTypes.find(item => item.id === row.resourceTypeLookupId)?.typeName || row.resourceTypeLookupId
        },
        {
            label: 'Company',
            name: 'companyName'
        },
        {
            label: 'Name',
            name: 'firstName'
        },
        {
            label: 'Phone',
            name: 'phoneNumber',
            render: ({ row }) => {
                return <div>
                    {row.phoneNumber.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3').toString()}
                </div>
            }
        },
        {
            label: 'Email',
            name: 'emailAddress'
        },
        {
            label: 'ACTIONS',
            name: 'actions',
            render: ({ row }) => (
                <ResourceAction row={row} callback={updateResources} toggleEdit={toggleResource} />
            )
        },
    ];

    return (
        <div className={cn(style.pageContainer)}>
            <div role='presentation'>
                <Breadcrumbs aria-label='breadcrumb' className={cn(style.breadcrumbsBox)}>
                    <Link underline='hover' color='inherit' href='/'>
                        Home
                    </Link>
                    <Typography color='text.primary'>Resources</Typography>
                </Breadcrumbs>
            </div>
            <div className={cn(style.contentBox)}>
                <Box className={cn(style.contentBoxPanel)} >
                    <div className={cn(style.contentBoxTitle)} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Grid sx={{ display: 'flex', gap: 1, fontStyle: 'italic' }}>
                            <WorkspacesOutlinedIcon />
                            <Typography color='text.primary' style={{ fontWeight: 600 }}>Resources</Typography>
                        </Grid>
                        <Grid>
                            <Button
                                variant='contained'
                                fullWidth
                                onClick={() => toggleResource()}
                                startIcon={<AddSharpIcon />}
                            >
                                New Resource
                            </Button>
                        </Grid>
                    </div>
                    <div style={{ width: '100%', padding: '0px 26px 20px' }}>
                        {isLoading && <LinearProgress />}
                        <DataGrid
                            cells={cells}
                            data={resources}
                        />
                        {(!resources.length && !isLoading) && <Alert severity='info' >
                            <strong>No Resource available</strong>
                        </Alert>}
                    </div>
                </Box>
            </div>
            {showResourceForm && <ResourceForm
                isShow={showResourceForm}
                onToggle={toggleResourceForm}
                callback={updateResources}
                resource={resource}
            />}
        </div>
    )
}

export default Resource