import { useNavigate } from 'react-router-dom'
import { Container, Grid, Button, Card, Typography } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signUpValidationSchema, signUpInitialValues } from 'utils'
import { useAPI, useNotification } from 'hooks'
import { TextInputField, PasswordInputField, AutocompleteField, PhoneNumberField } from 'components'
import apis from 'apis'
import cn from 'classnames'
import style from 'pages/styles/signin.module.scss'
import { Logo } from 'assets'
import { TSignUpInitialValues } from 'types/auth'
import useUserStore from 'store/userStore'

const SignUp = () => {
  const navigate = useNavigate()
  const notification = useNotification()
  const { setUserDetails } = useUserStore()
  const { data: titleList } = useAPI(apis.auth.registerTitles)

  const formProps = useForm({
    mode: 'all',
    resolver: yupResolver(signUpValidationSchema),
    defaultValues: signUpInitialValues,
  })
  const {
    formState: { isValid, isDirty },
    watch,
    handleSubmit,
  } = formProps
  const title = watch('title')
  const isNmls = title?.value == 1 || title?.value == 2

  const titleOptions = titleList?.data?.map((i) => {
    return { value: i.id, label: i.title }
  })

  const submitHandler = async (values: TSignUpInitialValues) => {
    const firstName = values.name.split(' ')[0]
    const lastName = values.name.split(' ')[1]

    const { title } = values
    if (!title) return
    const payload = {
      companyName: values.companyName,
      companyNMSLID: parseInt(values.companyNmlsId),
      userFirstName: firstName,
      userLastName: lastName,
      userEmail: values.email,
      userPhone: values.phone,
      userPassword: values.password,
      userTitleId: +title?.value,
      userNMLSID: (values.nmlsId && parseInt(values.nmlsId)) || null,
    }

    try {
      const res = await apis.auth.register(payload)
      setUserDetails(res.data.data)
      localStorage.setItem('token', res.data.data.token)
      navigate('/')
    } catch (err: any) {
      notification.showError(err.response.data.message || 'Something went wrong!')
    }
  }
  return (
    <div className={cn(style.wrapper)}>
      <Container className={cn(style.containerCard)}>
        <Card className={cn(style.shadowCard)}>
          <Grid container spacing={0} className={cn(style.innerContainer)}>
            <Grid item sm={12} lg={4}>
              <div className={cn(style.leftBox)}>
                <img src={Logo} alt='logo' className={cn(style.logo)} />
                <Typography variant='h4' marginBottom='10px' color='#fff' align='center'>
                  Welcome!
                </Typography>
                <Typography color='#fff' marginBottom='20px' align='center'>
                  Already have an account? Click the button below to sign in.
                </Typography>
                <Button
                  variant='outlined'
                  className={cn(style.whiteBorderBtn)}
                  onClick={() => navigate('/sign-in')}
                >
                  SIGN IN
                </Button>
              </div>
            </Grid>
            <Grid item sm={12} lg={8}>
              <div className={cn(style.formboxWrapper)}>
                <div className={cn(style.formBox)}>
                  <FormProvider {...formProps}>
                    <div className={cn(style.form)}>
                      <Grid container spacing={2}>
                        <Grid item container spacing={2} marginBottom='10px'>
                          <Grid item sm={12}>
                            <Typography
                              variant='h5'
                              align='center'
                              className={cn(style.Title1)}
                              color='#5D596C;'
                            >
                              Broker Plan
                            </Typography>
                          </Grid>
                          <Grid item sm={12}>
                            <Typography
                              variant='subtitle2'
                              className={cn(style.subTitle2)}
                              color='rgba(0, 0, 0, 0.6);'
                            >
                              Company
                            </Typography>
                          </Grid>
                          <Grid item sm={12} lg={6}>
                            <TextInputField label='Name' name='companyName' />
                          </Grid>
                          <Grid item sm={12} lg={6}>
                            <TextInputField label='NMLS ID' name='companyNmlsId' />
                          </Grid>
                        </Grid>
                        <Grid item container spacing={2} marginBottom='60px'>
                          <Grid item sm={12}>
                            <Typography
                              variant='subtitle2'
                              className={cn(style.subTitle2)}
                              color='rgba(0, 0, 0, 0.6);'
                            >
                              Account Information
                            </Typography>
                          </Grid>
                          <Grid item sm={12} lg={6}>
                            <TextInputField label='Name' name='name' />
                          </Grid>
                          <Grid item sm={12} lg={6}>
                            <AutocompleteField
                              textFieldProps={{ label: 'Title' }}
                              name='title'
                              options={titleOptions || []}
                            />
                          </Grid>
                          {isNmls && (
                            <Grid item sm={12} lg={12}>
                              <TextInputField label='NMLS ID' name='nmlsId' />
                            </Grid>
                          )}
                          <Grid item sm={12} lg={6}>
                            <TextInputField label='Email' name='email' />
                          </Grid>
                          <Grid item sm={12} lg={6}>
                            <PhoneNumberField label='Phone' name='phone' />
                          </Grid>
                          <Grid item sm={12} lg={6}>
                            <PasswordInputField label='Password' name='password' />
                          </Grid>
                          <Grid item sm={12} lg={6}>
                            <PasswordInputField label='Confirm Password' name='confirmPassword' />
                          </Grid>
                        </Grid>

                        <Grid item sm={12}>
                          <Button
                            disabled={!isValid || !isDirty}
                            variant='contained'
                            size='large'
                            fullWidth
                            onClick={handleSubmit(submitHandler)}
                          >
                            CONTINUE
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </FormProvider>
                </div>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </div>
  )
}

export default SignUp
