import { yupResolver } from '@hookform/resolvers/yup'
import { SetStateAction, useEffect, useMemo } from 'react'
import { Button, Typography, Grid } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'

import { Drawer, AutocompleteField, TextInputField, PhoneNumberField } from 'components'
import { useNotification } from 'hooks'
import { TResource, TResourceInitialValues } from 'types/resource'
import { resourceValidationSchema, ResourceInitialValues } from 'utils'
import apis from 'apis'
import { TResourceType } from 'types/global.types'
import useUserStore from 'store/userStore'

type TProps = {
    onToggle: (value?: SetStateAction<boolean> | undefined) => void
    isShow: boolean
    resource: TResource | null
    callback: (x: TResource, y: string) => void
}

const ResourceForm = ({ onToggle, isShow, resource, callback }: TProps) => {
    const notification = useNotification();
    const { userDetails, resourceTypes } = useUserStore();
    const titleOptions = useMemo(() => {
        return resourceTypes?.map((item: TResourceType) => ({ value: item.id, label: item.typeName })) || []
    }, [resourceTypes])

    const formProps = useForm({
        mode: 'all',
        resolver: yupResolver(resourceValidationSchema),
        defaultValues: { ...ResourceInitialValues },
    })

    const {
        formState: { isValid, isDirty },
        handleSubmit,
        reset,
    } = formProps

    useEffect(() => {
        if (resource?.id) {
            const { resourceAssistant, ...rest } = resource;
            reset({
                ...ResourceInitialValues,
                ...rest,
                resourceTypeLookupId: titleOptions?.find(({ value }: { value: number }) => (resource.resourceTypeLookupId === value)) || null,
                assistantFirstName: resourceAssistant?.firstName || '',
                assistantLastName: resourceAssistant?.lastName || '',
                assistantEmail: resourceAssistant?.emailAddress || '',
                assistantPhone: resourceAssistant?.phoneNumber || '',
            });
        }
    }, [resource])

    const submitHandler = async (values: TResourceInitialValues) => {
        try {
            const { assistantFirstName, assistantLastName, assistantPhone, assistantEmail, ...rest } = values;
            const resourceAssistant = {
                id: resource?.resourceAssistant?.id || 0,
                resourceId: resource?.id || 0,
                firstName: assistantFirstName,
                lastName: assistantLastName,
                emailAddress: assistantEmail,
                phoneNumber: assistantPhone
            }
            const payload = {
                ...rest,
                id: resource?.id || 0,
                resourceOwner: userDetails?.userId || 0,
                resourceTypeLookupId: +(values.resourceTypeLookupId?.value || 0),
                resourceAssistant,
                hasAssistant: true,
                userID: userDetails?.userId || 0
            }
            const action = resource?.id ? apis.resource.updateResource : apis.resource.addResource;
            const respo = await action(payload);
            notification.showSuccess(respo.data.message);
            setTimeout(() => {
                callback(payload, payload.id ? 'edit' : 'add')
                onToggle()
            }, 300)
        } catch (err: any) {
            notification.showError(err.response?.data?.message || 'Something went wrong!')
        }
    }

    return (
        <Drawer
            isShow={isShow}
            onToggle={onToggle}
            title={resource?.id ? 'Edit Resource' : 'Create A New Resource'}
            subTitle={resource?.id ? 'Enter the information below to edit your resource.' : 'Enter the information below to create a new Resource.'}
        >
            <FormProvider {...formProps}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextInputField label='Company Name' name='companyName' size='small' />
                    </Grid>
                    <Grid item sm={12}>
                        <AutocompleteField
                            name='resourceTypeLookupId'
                            options={titleOptions}
                            textFieldProps={{ size: 'small', label: 'Title' }}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInputField label='First Name' name='firstName' size='small' />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInputField label='Last Name' name='lastName' size='small' />
                    </Grid>
                    <Grid item sm={12}>
                        <PhoneNumberField label='Phone' name='phoneNumber' size='small' />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInputField label='Email' name='emailAddress' size='small' />
                    </Grid>

                    <Grid item sm={12}>
                        <Typography variant='h6' >
                            Assistant
                        </Typography>
                        <Typography >
                            The assistant of the resource that will also receive E-statement updates.
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <TextInputField label='First Name' name='assistantFirstName' size='small' />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInputField label='Last Name' name='assistantLastName' size='small' />
                    </Grid>
                    <Grid item sm={12}>
                        <PhoneNumberField label='Phone' name='assistantPhone' size='small' />
                    </Grid>
                    <Grid item sm={12}>
                        <TextInputField label='Email' name='assistantEmail' size='small' />
                    </Grid>
                    <Grid item sm={12}>
                        <Button
                            disabled={!isDirty || !isValid}
                            variant='contained'
                            size='medium'
                            fullWidth
                            onClick={handleSubmit(submitHandler)}
                        >
                            {resource?.id ? 'Save Resource' : 'Create Resource'}
                        </Button>
                    </Grid>
                </Grid>
            </FormProvider>
        </Drawer>
    )
}

export default ResourceForm;