import { TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { TDataGridHeadCell, Order } from './types';

const DESC = 'DESC';
// const ASC = 'ASC';

type TTableHeaderProps = {
    cells: TDataGridHeadCell[],
    onSortHandler?: (event: React.MouseEvent<unknown>, property: string) => void;
    order: Order;
    orderBy: string;
}

const TableHeader = (props: TTableHeaderProps) => {
    const { cells, order, orderBy, onSortHandler } = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        if (onSortHandler) {
            onSortHandler(event, property);
        }
    };

    return (
        <TableHead>
            <TableRow>
                {cells.map((cell: TDataGridHeadCell, index: number) => (
                    <TableCell
                        key={`${cell.name}-${index}`}
                        align='left'
                        {...cell.props || {}}
                    // sortDirection={orderBy === cell.name ? order : false}
                    >
                        {cell.sortable ? <TableSortLabel
                            active={orderBy === cell.name}
                            // direction={orderBy === cell.name ? order : ASC}
                            onClick={createSortHandler(cell.name)}
                        >
                            {cell.label}
                            {orderBy === cell.name ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === DESC ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                            : cell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default TableHeader;