import { Box } from '@mui/material'
import React from 'react'

type TProps = { size?: number; color?: string }

const Dot: React.FC<TProps> = ({ size, color }) => {
  const _size = size ?? 4
  const shapeStyles = { bgcolor: color || 'primary.main', width: _size, height: _size }
  const shapeCircleStyles = { borderRadius: '50%' }

  return (
    <Box
      component='span'
      // style={{ background: color }}
      sx={{ ...shapeStyles, ...shapeCircleStyles }}
    />
  )
}

export default Dot
