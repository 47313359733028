import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LinearProgress } from '@mui/material';
import { TLoanInformation } from 'types/contact'
import apis from 'apis'
import useAPIMethod from 'hooks/useApiMethod'
import { LoanInformation, LoanProgress } from './CaseStatusUI'

const LoanInformationIV = {
  id: 0,
  contactId: 0,
  contractAcceptance: '',
  escrowDays: null,
  closingDate: null,
  targetDate: null,
  interestRateLockTracker: {
    daysLocked: null,
    expirationDate: null,
    id: 0,
    loanInformationId: 0
  },
  loanCheckLists: [],
  loanChecklistTasks: [],
  loanMilestones: []
}

const milestones = [
  { id: 1, isComplete: true, loanChecklistLookupId: 0 },
  { id: 2, isComplete: false, loanChecklistLookupId: 1 },
  { id: 3, isComplete: false, loanChecklistLookupId: 2 },
  { id: 4, isComplete: false, loanChecklistLookupId: 3 },
  { id: 5, isComplete: false, loanChecklistLookupId: 5 },
  { id: 6, isComplete: false, loanChecklistLookupId: 12 },
  { id: 7, isComplete: false, loanChecklistLookupId: 13 }
];

const CaseStatus = () => {
  const [milestonesMap, setMilestonesMap] = useState<any>([])
  const [loanInformationData, setLoanInformationData] = useState<TLoanInformation>(LoanInformationIV)
  const { callApi, isLoading } = useAPIMethod()
  const { id: contactId = '' } = useParams()

  const getLoanInformationData = async () => {
    try {
      const res = await callApi(() => apis.contact.getLoanInformation(contactId))
      if (res.data) {
        const { loanCheckLists } = res.data;
        updateMilestonesMap(loanCheckLists)
        setLoanInformationData(res.data)
      }
    } catch {
      // err
    }
  }

  useEffect(() => {
    getLoanInformationData();
  }, [contactId])

  const updateLoanData = (values: TLoanInformation, type?: string) => {
    setLoanInformationData({ ...loanInformationData, ...values, })
    if (type) {
      updateMilestonesMap(values.loanCheckLists)
    }
  }

  const updateMilestonesMap = (checkLists: any) => {
    const updatedMilestones = milestones.map(item => {
      const loanCheckList = checkLists.find((loan: any) => (loan.loanChecklistLookupId === item.loanChecklistLookupId))
      if (loanCheckList) {
        item.isComplete = loanCheckList.isComplete
      }
      return item
    })
    setMilestonesMap(updatedMilestones)
  }

  if (isLoading) {
    return <LinearProgress />;
  }

  if (!loanInformationData?.id) {
    return null;
  }

  return (
    <>
      <div>
        <LoanInformation contactId={contactId} loanData={loanInformationData} callback={updateLoanData} />
        <LoanProgress loanData={loanInformationData} callback={updateLoanData} milestonesMap={milestonesMap} />
      </div>
    </>
  )
}

export default CaseStatus
