import { TRegisterPayload, TRegisterResponse, TUserTitles } from 'types/auth'
import { httpInstance } from '../config/httpInstance'

const baseRoute = '/Auth'

const login = <T>(payload: { userName: string; password: string }) => {
  return httpInstance.post<T>(`${baseRoute}/Login`, payload)
}

const register = (payload: TRegisterPayload) => {
  return httpInstance.post<TRegisterResponse>(`${baseRoute}/SignUp`, payload)
}

const registerTitles = () => {
  return httpInstance.get<TUserTitles>('/Commons/GetUserTitles')
}

export default {
  login,
  register,
  registerTitles,
}
