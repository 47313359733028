const HistoryTabs = () => {

  return (
    <>
      History
    </>
  )
}

export default HistoryTabs
