import { httpInstance } from './../config/httpInstance'
import { TResponseModel, TDashboard, TMyTeam } from 'types/global.types'

const dashboards = () => httpInstance.get<TResponseModel<TDashboard>>('/Dashboards')

const getTeams = () => httpInstance.get<TResponseModel<TMyTeam[]>>('User/Team');

export default {
  dashboards,
  getTeams
}
