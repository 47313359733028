import React, { FC, useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import {
  AutocompleteField,
  DatePickerField,
  SelectField,
  TextInputField,
  PhoneNumberField,
} from 'components'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { TAdditionalBorrower } from 'types/contact'
import apis from 'apis'
import { useApiMethod, useNotification } from 'hooks'
import { LoadingButton } from '@mui/lab'
import { BORROWING_TYPE, CITIZENSHIP, REL_TYPE } from 'helpers/constants'
import { yupResolver } from '@hookform/resolvers/yup'
import { additionalBorrowerValidator } from 'utils'
import cn from 'classnames'
import styles from './styles/additionalBorrorwer.module.scss'

const initialValues = {
  _id: 0,
  id: 0,
  contactId: 0,
  firstName: '',
  lastName: '',
  relationshipStatus: '',
  dateOfBirth: null,
  citizenship: '',
  borrowingType: '',
  emailAddress: '',
  phoneNumber: '',
  notes: '',
}

const BorrowerForm = () => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    name: 'borrower', // unique name for your Field Array
    keyName: undefined,
  })

  const addBorrower = () => {
    insert(fields.length, initialValues)
  }

  return (
    <>
      {fields.map((f, i) => (
        <div key={f.id + i} style={{ marginBottom: '20px' }}>
          <Box
            className={cn(styles.borrowerLabel)}
            sx={{
              display: 'flex',
              gap: '24px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant='h6' sx={{ marginBottom: '20px' }}>
              Additional Borrower
              {!!f._id && (
                <span style={{ opacity: '.6', marginLeft: '10px' }}>
                  {f.firstName} {f.lastName}
                </span>
              )}
              {fields.length > 1 && (
                <Button
                  className={cn(styles.closeBtn)}
                  size='small'
                  sx={{ opacity: '.7', marginLeft: '10px', paddingX: '10px' }}
                  color='inherit'
                  startIcon={'x'}
                  onClick={() => remove(i)}
                >
                  Remove
                </Button>
              )}
            </Typography>
            {i === 0 && (
              <Button variant='outlined' size='small' onClick={addBorrower}>
                + Borrower
              </Button>
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextInputField label='First Name' name={`borrower.${i}.firstName`} size='small' />
              </Grid>
              <Grid item xs={4}>
                <TextInputField label='Last Name' name={`borrower.${i}.lastName`} size='small' />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  textFieldProps={{ label: 'Relationship status', size: 'small' }}
                  name={`borrower.${i}.relationshipStatus`}
                  options={REL_TYPE}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <DatePickerField
                  label='Date of Birth'
                  name={`borrower.${i}.dateOfBirth`}
                  size='small'
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  textFieldProps={{ label: 'Citizenship', size: 'small' }}
                  name={`borrower.${i}.citizenship`}
                  options={CITIZENSHIP}
                />
              </Grid>
              <Grid item xs={4}>
                <AutocompleteField
                  textFieldProps={{ label: 'Borrowing Type', size: 'small' }}
                  name={`borrower.${i}.borrowingType`}
                  options={BORROWING_TYPE}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextInputField
                  label='Email'
                  name={`borrower.${i}.emailAddress`}
                  size='small'
                  placeholder='ex: joe.doe@gmail.com'
                />
              </Grid>
              <Grid item xs={6}>
                <PhoneNumberField
                  label='Phone'
                  name={`borrower.${i}.phoneNumber`}
                  size='small'
                  placeholder='(XXX) XXX-XXXX'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item flex={1}>
                <TextInputField
                  size='small'
                  multiline
                  rows={4}
                  label='Note'
                  name={`borrower.${i}.notes`}
                  placeholder='Add note here...'
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      ))}
    </>
  )
}

type TProps = {
  data: TAdditionalBorrower[]
  contactId: number
  callBack: () => Promise<void>
}
const AdditionalBorrower: FC<TProps> = ({ data, callBack, contactId }) => {
  const notification = useNotification()
  const { callApi: saveBorrowers, isLoading, error } = useApiMethod()

  const v = { borrower: data.length ? data : [initialValues] }
  const values = {
    borrower: v.borrower.map((v) => ({
      ...v,
      _id: v.id,
      relationshipStatus: REL_TYPE.find((r) => r.value === v?.relationshipStatus),
      citizenship: CITIZENSHIP.find((i) => i.value === v?.citizenship),
      borrowingType: BORROWING_TYPE.find((b) => b.value === v?.borrowingType),
    })),
  }
  const formProps = useForm({
    mode: 'all',
    resolver: yupResolver(additionalBorrowerValidator),
    defaultValues: values,
  })

  const {
    reset,
    formState: { isValid, isDirty },
    handleSubmit,
  } = formProps

  useEffect(() => {
    reset(values)
  }, [data])

  const onSaveChanges = async (values: any) => {
    const payload: TAdditionalBorrower[] = values.borrower.map((v: any, i: number) => ({
      id: v._id,
      contactId: contactId,
      firstName: v.firstName,
      lastName: v.lastName,
      relationshipStatus: v.relationshipStatus?.value || '',
      dateOfBirth: v.dateOfBirth || null,
      citizenship: v.citizenship?.value || '',
      borrowingType: v.borrowingType?.value || '',
      emailAddress: v.emailAddress,
      phoneNumber: v.phoneNumber,
      notes: v.notes,
    }))

    try {
      await saveBorrowers(() => apis.contact.saveAdditionalBorrowers(payload))
      notification.showSuccess('Borrower Saved.')
      callBack()
    } catch (err) {
      notification.showError('Something Went Wrong.')
    }
  }

  return (
    <FormProvider {...formProps}>
      <BorrowerForm />
      <Grid item xs={12} sx={{ marginTop: '40px' }} className='actionBtn'>
        <LoadingButton
          disabled={!isValid || !isDirty}
          loading={isLoading}
          variant='contained'
          size='large'
          className='saveBtn'
          onClick={handleSubmit(onSaveChanges)}
        >
          Save Changes
        </LoadingButton>
        <Button variant='text' size='large' className='cancelBtn'>
          Cancel
        </Button>
      </Grid>
    </FormProvider>
  )
}

export default AdditionalBorrower
