import * as React from 'react';
import Box from '@mui/material/Box';
import { TableContainer, Table, TableBody, TableRow, TableCell, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themeColor } from 'theme';
import { TDataGridHeadCell, Order } from './types';
import TableHeader from './TableHeader';
import TableToolbar from './TableToolbar';

const DESC = 'DESC';
const ASC = 'ASC';

const StyleTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': {
      '& th': {
        fontSize: 'inherit',
        textTransform: 'uppercase',
        padding: '12px 8px',
      }
    }
  },
  '& tbody': {
    '& tr': {
      '& td': {
        fontSize: 'inherit',
        padding: '12px 8px',
        color: themeColor.light.secondaryTextColor
      }
    }
  }
}));

type TProps = {
  data: Array<any>
  cells: Array<any>
  toolbarProps?: any
  hidePagination?: boolean;
}

const DataGrid = ({ data = [], cells = [], toolbarProps, hidePagination }: TProps) => {
  const [order, setOrder] = React.useState<Order>(ASC);
  const [orderBy, setOrderBy] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === ASC;
    setOrder(isAsc ? DESC : ASC);
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderData = hidePagination ? data : data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ width: '100%', border: '1px solid #DBDADE' }}>
      <TableToolbar {...toolbarProps} />
      <TableContainer sx={{ backgroundColor: 'unset' }}>
        <StyleTable>
          <TableHeader
            order={order}
            orderBy={orderBy}
            onSortHandler={handleRequestSort}
            cells={cells}
          />
          <TableBody>
            {renderData.map((row: any, index: number) => (
              <TableRow hover tabIndex={-1} key={`tr-${index}`}>
                {cells.map((cell: TDataGridHeadCell, cellIndex: number) => (
                  <TableCell key={`tr-cl-${index}-${cell.name}-${cellIndex}`}
                    {...cell.props || {}}
                    sx={{ whiteSpace: 'normal', ...cell.props?.sx }}
                  >
                    {cell.render?.({ row, index }) || row[cell.name]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </StyleTable>
      </TableContainer>
      {(!!data.length && !hidePagination) && <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage=""
        labelDisplayedRows={({ from, to, count }) => `Showing ${from} to ${to} of ${count} entries`}
      />}
    </Box>
  );
}

export default DataGrid;