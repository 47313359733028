import { TextField, MenuItem, TextFieldProps } from '@mui/material'
import { useController } from 'react-hook-form'
import { TEvent } from 'types/global.types'

type TSeletField = TextFieldProps & {
  name: string
  options: { value: string | number; label: string; icon?: any }[]
  callback?: (e: any) => void
}

const SelectField = ({ name, options, callback, ...props }: TSeletField) => {
  const { field, fieldState } = useController({ name, defaultValue: '' })

  const handleChange = (e: TEvent) => {
    const { value } = e.target
    if (callback) {
      callback(value);
    }
    field.onChange(value)
  }

  const config = {
    ...field,
    select: true,
    fullWidth: true,
    ...props,
    onChange: handleChange,
  }

  if (fieldState && fieldState.error) {
    config.error = true
    config.helperText = fieldState.error.message
  }

  return (
    <TextField variant='outlined' {...config}>
      {options.map((opt, index) => {
        const sxProps: any = {}
        if (opt.icon) {
          sxProps.display = 'flex'
        }

        return (
          <MenuItem key={index} value={opt.value}>
            {opt.icon ? <div style={sxProps}>
              <img src={opt.icon} alt='icon' style={{ marginRight: '10px' }} />
              <span>{opt.label}</span>
            </div>
              : opt.label}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export default SelectField
