import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { Box, LinearProgress, Alert } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { Bell, ChartLine, Circles, File, History, User, Flag } from 'assets/svg'
import cn from 'classnames'
import style from './styles/contacts.module.scss'
import TabPanel from 'components/General/TabPanel'
import ContactTabs from './ContactTabs'
import TaskTabs from './TaskTabs'
import GoalTabs from './GoalTabs'
import ResourceTabs from './ResourceTabs'
import StipulationTabs from './StipulationTabs'
import LoanInfoTabs from './LoanInfoTabs'
import HistoryTabs from './HistoryTabs'
import { useApiMethod } from 'hooks'
import apis from 'apis'
import useUserStore from 'store/userStore'

// import { useState } from "react";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const contactsTabs = [
  {
    label: 'Contact',
    icon: <img src={User} alt='User' />,
    component: ContactTabs,
  },
  {
    label: 'Resources',
    icon: <img src={Circles} alt='Circles' />,
    component: ResourceTabs,
  },
  {
    label: 'Goals',
    icon: <img src={ChartLine} alt='ChartLine' />,
    component: GoalTabs,
  },
  {
    label: 'Status & Tasks',
    icon: <img src={Bell} alt='Bell' />,
    component: TaskTabs,
  },
  {
    label: 'Loan Info',
    icon: <img src={File} alt='File' />,
    component: LoanInfoTabs,
  },
  {
    label: 'Stipulations',
    icon: <img src={Flag} alt='Flag' />,
    component: StipulationTabs,
  },
  {
    label: 'History',
    icon: <img src={History} alt='History' />,
    component: HistoryTabs,
  },
]

type TContent = {
  isLoading: boolean
  error?: any
  currentTab: any
  contactApiCallBack: () => Promise<void>
}

const TabContent = ({ isLoading, error, currentTab, contactApiCallBack }: TContent) => {
  const { contactInfo } = useUserStore()

  if (isLoading && !contactInfo) return <LinearProgress />

  if (error && !isLoading)
    return (
      <Alert
        severity='error'
        sx={{
          m: '25px',
        }}
      >
        <strong>Something went wrong,</strong> please try again
      </Alert>
    )

  if (!contactInfo)
    return (
      <Alert severity='warning' sx={{ m: '25px' }}>
        User not found
      </Alert>
    )

  return (
    contactsTabs.map((tab, index) => (
      <TabPanel value={currentTab} index={index} key={index}>
        <tab.component contactApiCallBack={contactApiCallBack} />
      </TabPanel>
    ))
  )
}

const ContactInfo = () => {
  const { id } = useParams()
  const [currentTab, setCurrentTab] = useState(3)
  const { setContactInfo } = useUserStore()

  const { callApi: getContactData, isLoading: cLoading, error: cError } = useApiMethod();

  const isLoading = cLoading
  const error = cError

  useEffect(() => {
    setCurrentTab(3)
  }, [id])

  const getContactDataApi = async () => {
    const res = await getContactData(() => apis.contact.getContactDetailsByid(id || 0))
    setContactInfo(res.data)
  }

  useEffect(() => {
    getContactDataApi()
  }, [id])

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  return (
    <div className={cn(style.contacts)}>
      <div role='presentation'>
        <Breadcrumbs aria-label='breadcrumb' className={cn(style.breadcrumbsBox)}>
          <Link underline='hover' color='inherit' href='#'>
            Contacts
          </Link>
          <Typography color='text.primary'>Contact Details</Typography>
        </Breadcrumbs>
      </div>
      <div className={cn(style.tabingBox)}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs value={currentTab} onChange={handleChange} aria-label='basic tabs example'>
              {contactsTabs.map((tab, index) => (
                <Tab
                  key={index}
                  className={cn(style.tabButton)}
                  icon={tab.icon}
                  label={tab.label}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
          <Box className={cn(style.tabingBoxPanel)}>
            {TabContent({ isLoading, error, currentTab, contactApiCallBack: getContactDataApi })}
          </Box>
        </Box>
      </div>
    </div>
  )
}

export default ContactInfo
