import React, { useState, useRef } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, Typography, CircularProgress } from '@mui/material';
import {
    MoreVert as MoreVertIcon,
    EditOutlined as EditOutlinedIcon,
    DeleteForeverOutlined as DeleteForeverOutlinedIcon
} from '@mui/icons-material';
import apis from 'apis'
import { TResource } from 'types/resource';
import { ConfirmDialog } from 'components';
import { useNotification, useApiMethod } from 'hooks';

type TProps = {
    row: TResource
    callback: (x: TResource, y: string) => void
    toggleEdit: (x: TResource) => void
}

const ResourceAction = ({ row, callback, toggleEdit }: TProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const confirmRef = useRef<any>(null);
    const notification = useNotification();
    const { callApi, isLoading } = useApiMethod();

    const onClickActionHandler = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onClickEdit = async (row: TResource) => {
        toggleEdit(row);
        setAnchorEl(null);
    }

    const onClickDelete = () => {
        setAnchorEl(null);
        confirmRef.current?.open();
    }

    const onDeleteConfirm = async () => {
        try {
            await callApi(() => apis.resource.deleteResource(row.id))
            setAnchorEl(null);
            callback(row, 'delete');
        } catch {
            notification.showError('Error in deleting values!')
        }
    }

    return (
        <div>
            <ConfirmDialog ref={confirmRef} title='Delete Resource' content='Are you sure to delete this resource ?' onConfirm={onDeleteConfirm} />
            <IconButton
                className='toggleEllipseColor'
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={onClickActionHandler}
            >
                {isLoading ? <CircularProgress size={20} />
                    : <MoreVertIcon />}
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => onClickEdit(row)}>
                    <ListItemIcon>
                        <EditOutlinedIcon />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>Edit</Typography>
                </MenuItem>
                <MenuItem onClick={() => onClickDelete()}>
                    <ListItemIcon>
                        <DeleteForeverOutlinedIcon color='error' />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>Delete</Typography>
                </MenuItem>
            </Menu>
        </div>
    )
}

export default ResourceAction;