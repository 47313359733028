import { useParams } from 'react-router-dom'
import { Grid, Button, Typography, ButtonGroup } from '@mui/material'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInputField, SelectField } from 'components'
import { contactNoteValidator, noteTypeOptions, NoteInitialValues, dateMDYMH } from 'utils'
import { TNote } from 'types/contact'
import { useNotification } from 'hooks'
import apis from 'apis'
import useUserStore from 'store/userStore'

type TProps = {
    callback: () => void
}

const AddNote = ({ callback }: TProps) => {
    const { id: contactId = 0 } = useParams()
    const notification = useNotification()
    const { userDetails } = useUserStore()

    const formProps = useForm({
        mode: 'all',
        resolver: yupResolver(contactNoteValidator),
        defaultValues: NoteInitialValues,
    })

    const {
        handleSubmit,
        formState: { isValid, isDirty },
        setValue,
        watch,
        reset
    } = formProps

    const noteValue = watch('note');

    const onSaveChanges = async (values: TNote) => {
        const payload = {
            ...values,
            contactId,
            isPrivateNote: values.type === 'privateNote',
            createdBy: userDetails?.name,
            updatedBy: userDetails?.name
        }
        try {
            const respo = await apis.contact.addNote(payload)
            notification.showSuccess(respo.data.message);
            reset(NoteInitialValues);
            callback();
        } catch {
            notification.showError('Error in saving values!')
        }
    }

    const onClickButtonTemplate = (type: string) => {
        const updatedValue = `${type} {${dateMDYMH(new Date())}} \n${noteValue}`;
        setValue('note', updatedValue);
    }

    const textBtnProps = {
        borderRight: 'unset !important',
        textTransform: 'capitalize !important'
    }

    return (
        <FormProvider {...formProps}>
            <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                    <Typography variant='h6' sx={{ fontWeight: '600' }}>
                        Create New Note
                        <ButtonGroup color='inherit' variant='text' size='small'>
                            <Button sx={textBtnProps} onClick={() => onClickButtonTemplate('Emailed')}>Emailed</Button>
                            -
                            <Button sx={textBtnProps} onClick={() => onClickButtonTemplate('Called')}>Called</Button>
                            -
                            <Button sx={textBtnProps} onClick={() => onClickButtonTemplate('Texted')}>Texted</Button>
                            -
                            <Button sx={textBtnProps} onClick={() => onClickButtonTemplate('Video')}>Video</Button>
                            -
                            <Button sx={textBtnProps} onClick={() => onClickButtonTemplate('Update')}>Update</Button>
                        </ButtonGroup>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextInputField
                        size='small'
                        multiline
                        rows={4}
                        name='note'
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: 'inline-flex', gap: '24px' }} direction='row-reverse'>
                    <Button
                        disabled={!isValid || !isDirty}
                        variant='contained'
                        size='medium'
                        className={'saveBtn'}
                        onClick={handleSubmit(onSaveChanges)}
                    >
                        Save
                    </Button>
                    <SelectField
                        name='type'
                        options={noteTypeOptions}
                        fullWidth={false}
                        size='small'
                        sx={{ width: '200px' }}
                    />
                </Grid>
            </Grid>
        </FormProvider>
    )
}

export default AddNote
