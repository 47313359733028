import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'
import { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

type TProps = {
  name: string
  placeholder?: string
  onChange?(e: React.ChangeEvent<HTMLInputElement>, x: string): void
  datePickerProps?: any
} & TextFieldProps

const DatePickerField: React.FC<TProps> = (props) => {
  const { name, onChange, datePickerProps = {}, ...rest } = props
  const { field, fieldState } = useController({ name, defaultValue: Dayjs })
  const handleChange = (e: any) => {
    field.onChange(e)
  }
  const config = {
    ...field,
    value: field.value || '',
    inputFormat: 'MM/DD/YYYY',
    ...datePickerProps,
    onChange: handleChange,
  }

  if (onChange) {
    config.onChange = onChange;
  }

  const { error } = fieldState

  const validation = {
    size: rest.size || 'small',
    error: !!error,
    helperText: error && 'Please enter valid date',
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...config}
        renderInput={(params) => <TextField fullWidth {...params} {...validation} {...rest} />}
      />
    </LocalizationProvider>
  )
}

export default DatePickerField
