import { Box, Divider, Tab, Tabs } from '@mui/material';
import {
  Home,
  ID,
  Users,
} from 'assets/svg'
import cn from 'classnames'
import style from '../styles/contacts.module.scss'
import TabPanel from 'components/General/TabPanel'
import { useState } from 'react'
import ContactDetails from './ContactDetails'
import AddressHistory from './AddressHistory'
import AdditionalBorrower from './AdditionalBorrower'
import TabHeader from '../TabHeader'
import useUserStore from 'store/userStore'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

type TContactTabs = {
  contactApiCallBack: () => Promise<void>
}

const ContactTabs = ({ contactApiCallBack }: TContactTabs) => {
  const [currentTab, setCurrentTab] = useState(0)
  const { contactInfo } = useUserStore()

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  if (!contactInfo) {
    return null;
  }

  const tabs = [
    {
      label: 'Contact Details',
      icon: <img src={ID} alt='ID' />,
      component: <ContactDetails contactInfo={contactInfo} callBack={contactApiCallBack} />,
    },
    {
      label: 'Address History',
      icon: <img src={Home} alt='Home' />,
      component: (
        <AddressHistory
          contactId={contactInfo.id}
          data={contactInfo.addressHistories}
          callBack={contactApiCallBack}
        />
      ),
    },
    {
      label: 'Additional Borrower',
      icon: <img src={Users} alt='Users' />,
      component: (
        <AdditionalBorrower
          contactId={contactInfo.id}
          data={contactInfo.additionalBorrowerList}
          callBack={contactApiCallBack}
        />
      ),
    },
  ]

  return (
    <>
      <TabHeader />
      <Divider />
      <div className={cn(style.tabingBoxBody)}>
        <Box sx={{ width: '100%' }}>
          <Box className={cn(style.tabingBoxTabTwo)}>
            <Tabs value={currentTab} onChange={handleChange} aria-label='basic tabs example'>
              {tabs.map((tab, index) => (
                <Tab key={index} icon={tab.icon} label={tab.label} {...a11yProps(index)} />
              ))}
            </Tabs>
          </Box>
          <Box className={cn(style.tabingBoxPanel2)}>
            {tabs.map((tab, index) => (
              <TabPanel value={currentTab} index={index} key={index}>
                {tab.component}
              </TabPanel>
            ))}
          </Box>
        </Box>
      </div>
    </>
  )
}

export default ContactTabs
